import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonModal,
    IonMenuButton
} from '@ionic/react';

import {
    // shareOutline,
    // heartSharp,
    // heartOutline,
    // arrowForwardOutline,
    // alertCircle,
    calendarOutline,
    // timeOutline,
    alertCircleOutline,
    arrowForwardOutline,
    chevronBack,
    // locationOutline
} from 'ionicons/icons';


import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';

import 'mapbox-gl/dist/mapbox-gl.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const DragTourWindow: React.FC<{
    dragExpos: any[];
    barsAndClubs: any[];
    likedDragExpos: any;
    setLikedDragExpos: any;
    // tvShows: any;
    people: any;
    peopleOth: any;
    currentDateAndTime: any;
    handleIncrClick: any;
}> = (props) => {

    const selectedDragExpoId = useParams<{ id: string }>().id;
    const selectedDragExpo = props.dragExpos.find(dragexpo => dragexpo.id === selectedDragExpoId);

    // console.log(selectedDragExpo, 'selected drag expo');

    
    const [isWindowMapOpenDE, setIsWindowMapOpenDE] = useState<any>(false);
    const [isWindowMapLoadedDE, setIsWindowMapLoadedDE] = useState<any>(false);
    // const [myMapDE, setMyMapDE] = useState<any>(null);

    const modalMapDE= useRef<HTMLIonModalElement>(null);
    const mapContainerDE = useRef<any>(null);

    const handleClickLinkDT = () => {
        window.open(selectedDragExpo.website, '_system', 'location=yes');
        props.handleIncrClick("dragtours", selectedDragExpo.hash);
    }


    useEffect(() => {

        if (!mapContainerDE.current) {
            return
        }

        const mapDE = new mapboxgl.Map({
            container: mapContainerDE.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedDragExpo?.lng, selectedDragExpo?.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedDragExpo?.lng, selectedDragExpo?.lat]).addTo(mapDE);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapDE(mapDE);

        return () => mapDE.remove();

    },[isWindowMapLoadedDE]);

    const handleDismissWindowMapModalDE = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because...
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedDE(false);
        setIsWindowMapOpenDE(false);
    }



    return(
        <IonPage>
            {
                selectedDragExpo ? (
                <IonContent>

                <IonModal
                ref={modalMapDE}
                className="windowmapmodal"
                isOpen={isWindowMapOpenDE}
                onDidDismiss={handleDismissWindowMapModalDE}
                onDidPresent={() => setIsWindowMapLoadedDE(true)}
                >

                <div className="mapcontainer">
                    <div ref={mapContainerDE} className="mapcontainerinner" />
                </div>
              
              
                </IonModal>

                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="ionbackbuttoncontoth">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                        </div>

                        <div className="windowtopcont">
                            {
                                selectedDragExpo.image_src
                                &&
                                <img className="windowimgbox" src={selectedDragExpo.image_src} alt=""
                                onError={(e: any) => e.target.src = selectedDragExpo.image_url}
                                ></img>

                            }
                            {
                                !selectedDragExpo.image_src
                                &&
                                <img className="windowimgbox" src={selectedDragExpo.image_url} alt=""
                                ></img>

                            }
                            <div className="windowimggrad">
                                {/* <div className="windowtitlebox">
                                    <div className="windowtitleshort">
                                        {selectedDragExpo.name}
                                    </div>
                                    <div className="titleboxright">
                                        {
                                        selectedDragExpo.tickets_available
                                        &&
                                        <div className="titleright">
                                            <div className="titlerightfrom">from</div>
                                            <div className="titlerightmarg">{selectedDragExpo.currency_symbol}{selectedDragExpo.starting_price}</div>
                                        </div>
                                        }
                                        {
                                        !selectedDragExpo.tickets_available
                                        &&
                                        <div className="titlerightsoldout">
                                            <div className="">SOLD</div>
                                            <div className="">OUT</div>
                                        </div>
                                        }
                                    </div>
                                </div> */}
                                <div className="windowtitlebox">

                                   
                                            <div className="windowtitlefull">
                                               
                                                {selectedDragExpo.name}

                                            </div>
     
                                        </div>

                            </div>
                        </div>
                       
                        <div className="windowmainsetwidth">

                        {/* <div className="windowdatetimecont">
                            <div className="datetimebox">
                                <IonIcon icon={calendarOutline} className="datetimeicon" />
                                <div className="datebox">
                                    <div className="datetimetext">
                                        {selectedDragExpo?.start_date}{" "}{selectedDragExpo?.start_month.slice(0,3)}{" '"}{selectedDragExpo.start_year.slice(-2)}
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="datetimebox">
                                <IonIcon icon={timeOutline} className="datetimeicon" />
                                <div className="timebox">
                                    <div className="datetimetext">
                                        {selectedDragExpo?.start_time}{" "}to{" "}{selectedDragExpo?.finish_time}
                                    </div>
                                </div>
                            </div>                  
                        </div> */}
                        
                        {
                        selectedDragExpo.type === "Tour"
                        &&
                        <div className="windowdatetimecont">
                            <div className="datetimebox">
                                
                                <div className="datebox">
                                    <div className="datetimetext">
                                        {selectedDragExpo.start_day.slice(0,3)}{","}&nbsp;{selectedDragExpo.start_date}&nbsp;{selectedDragExpo.start_month.slice(0,3)}
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="smalldot"></div>
                            <div className="datetimebox">
                               
                                <div className="timebox">
                                    
                                        <div className="datetimetext">
                                            {selectedDragExpo.start_time}&nbsp;to&nbsp;{selectedDragExpo.finish_time}
                                            
                                        </div>
                                    
                                    
                                </div>
                            </div> 
                           
                        </div>
                        }
                        {
                        selectedDragExpo.type === "Expo"
                        &&
                        <div className="windowdatetimecontf">
                            <div className="datetimeboxt">
                                
                                <IonIcon icon={calendarOutline} className="datetimeicon"/>
                                    <div className="datetimetext">
                                        {/* {selectedParty?.start_day.slice(0,3)}{", "} */}
                                        {/* {selectedParty?.start_date}{" "}{selectedParty?.start_month.slice(0,3)}{selectedParty?.start_year !== selectedParty?.finish_year ? ` ${selectedParty?.start_year}` : ""}{" - "} */}
                                        {/* <br/> */}
                                        {/* {selectedParty?.finish_day.slice(0,3)}{", "} */}
                                        {/* {selectedParty?.finish_date}{" "}{selectedParty?.finish_month.slice(0,3)}{" "}{selectedParty?.finish_year} */}
                                        
                                        
                                        {selectedDragExpo.datestring}
                                        {" "}{selectedDragExpo.finish_year}
                                    </div>
                            </div>
                            
                           
                        </div>
                        }
                        {/* {
                        selectedDragExpo?.timeframe !== "tour"
                        &&
                        <div className="windowdatetimecont">
                            <div className="datetimebox">
                                
                                <div className="datebox">
                                    <div className="datetimetext">
                                        {selectedDragExpo?.datestring}
                                    </div>
                                </div>
                            </div>
                            
                           
                        </div>
                        } */}
                        {
                        selectedDragExpo.type === "Tour"
                        &&
                                <div className="windowmainheadingstd">
                                    Performers
                                </div>
                        }
                        
                                    {/* end of set width main box */}
                        </div>






                        {
                        selectedDragExpo.type === "Tour"
                        &&
                                <div className="gridviewgridBC mgab">
                                    {
                                        props.peopleOth.filter((person: any) => selectedDragExpo.entertainers?.some((entertainer: any) => entertainer === person.name))
                                        .map((person: any, index: any) => {
                                            return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                                                <div className="gridviewcardstd mgbel">
                                                    {
                                                        person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_src} alt=""
                                                        onError={(e: any) => e.target.src = person.image_url}

                                                        />

                                                    }
                                                    {
                                                        !person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_url} alt=""
                                                        />

                                                    }
                                                    
                                                    <div className="gridviewcardimagecoverppl">
                                                 
                                                      {
                                                        person.type[0] === "Drag"
                                                        &&
                                                        <div className="newtypecont">Drag</div>
                                                        }      
                                                        {
                                                        person.type[0] === "DJ"
                                                        &&
                                                        <div className="newtypecont">DJ</div>
                                                        }         
                                                        {
                                                        person.type[0] === "Singer"
                                                        &&
                                                        <div className="newtypecont">Singer</div>
                                                        }          
                                                        {
                                                        person.type[0] === "Other"
                                                        &&
                                                        <div className="newtypecont">Other</div>
                                                        }
                                                        
                                                        
                                                        <div className="gridviewbottomcontainer19">               
                                                
                                                              
                                                                    <div className="gridviewtitlefull">
                                                                        {person.name}
                                                                    </div>
                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                                                    
                                                                
                                        })
                                        }
                                    </div>
                                }


                        


                        

                        <div className="windowmainsetwidth">




                        {
                                (selectedDragExpo.description !== "")
                                //i removed extra info conditional because in drag tours i currently have extra info without description. and that looks weird.
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {selectedDragExpo.description}
                                    </div>
                                        {/* {
                                    selectedDragExpo.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedDragExpo.extra_info}</div>
                                    </div>
                                    } */}
                                    {
                                            (selectedDragExpo.extra_info !== "")
                                            &&
                                            <div className="festbtncont">
                                                    

                                                    {
                                                    
                                                    selectedDragExpo.extra_info !== ""
                                                    &&
                                                    <div className="festtag">
                                                            <div className="triancont">
                                                            {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                            <IonIcon icon={alertCircleOutline} className="extalert" />
                                                            </div>
                                                            {selectedDragExpo.extra_info}
                                                    </div>
                                                    }
                                                    
                                                    
                                                </div>

                                        }
                                </div>
                            }









                        {
                            selectedDragExpo.entertainers !== ""
                            &&
                        selectedDragExpo.type === "Expo"
                        &&
                                <div className="windowmainheadingstd">
                                    Confirmed Attendees
                                </div>
                        }



                        {
                            selectedDragExpo.entertainers !== ""
                            &&
                        selectedDragExpo.type === "Expo"
                        &&
                                    <div className="windowgridfour">
                                    {
                                        props.people.filter((person: any) => selectedDragExpo.entertainers?.some((entertainer: any) => entertainer === person.name))
                                        .map((person: any, index: any) => {
                                            return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                                                <div className="gridviewcardppl">
                                                    {
                                                        person.image_src
                                                        &&
                                                        <img className="gridviewcardimageppl" src={person.image_src} alt=""
                                                        onError={(e: any) => e.target.src = person.image_url}

                                                        />
                                                    }
                                                    {
                                                        !person.image_src
                                                        &&
                                                        <img className="gridviewcardimageppl" src={person.image_url} alt=""
                                                        />
                                                    }
                                                    <div className="gridviewcardimagecoverpploth">
                                                        
                                                        
                                                        
                                                        <div className="gridviewbottomcontaineralt">               
                                                
                                                               
                                                                    <div className="gridviewtitlefulltiny">
                                                                        {person.name}
                                                                    </div>
                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                                                    
                                                                
                                        })
                                        }
                                    </div>
                        }







                            



                            {/* {
                                selectedDragExpo.price_options !== ""
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                            Pricing
                                    </div>
                                    
                                    <div className="pricingflexboxDEcontainer">
                                        {
                                            selectedDragExpo.price_options.map((option: any, index: number) => {
                                                return <div key={index} className="pricingflexboxDE">
                                                        <div className="pricingoptionsDEtype">{option[1]}</div>
                                                        <div className="pricingoptionsDEprice">{selectedDragExpo.currency_symbol}{option[0]}</div>
                                                    </div>
                                            })
                                        }

                                    </div>

                                </div>
                            } */}
                            
                            
                            {
                            props.barsAndClubs.filter(ele => ele.name === selectedDragExpo.venue).length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Venue
                            </div>
                            }
                            {/* end of set width main box */}

                        </div>

                        {
                            props.barsAndClubs.filter(ele => ele.name === selectedDragExpo.venue).length > 0
                            &&
                            <div className="gridviewgridBC mgab2">
                                {
                                    props.barsAndClubs.filter(ele => ele.name === selectedDragExpo.venue)
                                    .map((venue: any, index: number) => {
                                        return <div key={index} className="gridviewcardstd">
                                            <Link to={`/${venue.type === "barorclub" ? "barsandclubs" : "saunas"}/${venue.id}`} style={{ display: 'contents' }}>
                                        {
                                        (venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_src} alt="" 
                                        onError={(e: any) => e.target.src = venue.image_url}
                                        />
                                        }
                                        {
                                        !(venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_url} alt="" 
                                        />
                                        }
                                        <div className="gridviewcardimagecover">
                                           
                                            {/* <div className="gridviewbottomcontainermid">               
                                                <div className="gridviewtitlecontainerfull">
                                                    {
                                                        venue.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name}</div>
                                                    }
                                                    {
                                                        venue.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name_short}</div>
                                                    }
                                                </div>
                                            </div> */}
                                            <div className="gridviewbottomcontainer19">               
                                                
                                                    {
                                                        venue.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name}</div>
                                                    }
                                                    {
                                                        venue.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name_short}</div>
                                                    }
                                                
                                            </div>
                                        </div>
                                    </Link>   
                                        </div>

                                    })
                                }
                            </div>
                            }

                           
                                
                                {/* {
                                props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue).length > 0
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                    Venue
                                </div>
                                <div className="windowmainboxvenue">
                                    <div className="gridviewcardstd">
                                        <Link to={`/barsandclubs/${props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].id}`} style={{ display: 'contents' }}>
                                            {
                                            (props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].image_src} alt=""
                                            onError={(e: any) => e.target.src = props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].image_url}

                                            />
                                            }
                                            {
                                            !(props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].image_url} alt=""
                                            />
                                            }
                                            <div className="gridviewcardimagecover">
                                               
                                                <div className="gridviewbottomcontainer">               
                                                    <div className="gridviewtitlecontainerfull">
                                                    <div className="gridviewtitlefull">{props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>       
                                    </div>
                                </div>


                                </div>
                                } */}
                        <div className="windowmainsetwidth">
                            <div className="windowmainboxstd">
                                {
                                    props.barsAndClubs.filter(ele => ele.name === selectedDragExpo.venue).length === 0
                                    &&
                                    <div className="windowmainheadingstd">
                                        Address
                                    </div>
                                    }
                                    {
                                    props.barsAndClubs.filter(ele => ele.name === selectedDragExpo.venue).length === 0
                                    &&
                                    <div className="windowmainvenuecardbox">
                                        <div className="windowvenueleft">      
                                            {
                                            selectedDragExpo.venue !== ""
                                            &&
                                            <div className="addmgb">
                                                <span className="addresshasvenuename">{selectedDragExpo.venue}</span>
                                            </div>
                                            }
                                            {selectedDragExpo.suite_number === "" ? "" : <div>{selectedDragExpo.suite_number}</div>}
                                            <div className="addressfirstrowflexbox">
                                                
                                                {selectedDragExpo.street_number === "" ? <div>{selectedDragExpo.street_name}</div> : <div className="limitaddresstooneline">{selectedDragExpo.street_number}{" "}{selectedDragExpo.street_name}</div>}
                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                            </div>
                                            {/* <div>{selectedDragExpo?.suburb} {selectedDragExpo?.postcode}</div>     */}
                                            {selectedDragExpo.city !== "London" ? <div>{selectedDragExpo.suburb} {selectedDragExpo.postcode}</div> : <div><div>{selectedDragExpo.suburb}</div><div> {selectedDragExpo.postcode}</div></div>}
                                        </div>
                                        <div className="windowvenueright">  
                                            <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenDE(true)}>
                                                MAP
                                            </div>
                                        </div>
                                    </div>
                                }   

                            </div>

                            <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                selectedDragExpo.organiser !== ""
                                &&
                                <div
                                style={selectedDragExpo.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {selectedDragExpo.organiser}
                                    </div>
                                </div>
                                }
                                {
                                selectedDragExpo.ticket_provider !== ""
                                &&
                                <div
                                style={selectedDragExpo.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedDragExpo.ticket_provider}
                                    </div>
                                </div>
                                }
                            </div>




                            {/* {
                                (selectedDragExpo?.tickets_available)
                                &&
                                <div className="floatingbuttoncont">

                                  
                                
                                    <div className="mainwindowbtn"
                                    onClick={handleClickLinkDT}
                                    >
                                        <div className="floatbtnskew">
                                            GET A TICKET
                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                        </div>
                                    </div>

                                </div>
                                }
                                {
                                    (selectedDragExpo?.price === 0 || !selectedDragExpo?.tickets_available)
                                    &&
                                    <div className="floatingbuttoncont">

                                     

                                        <div className="mainwindowbtn"
                                        onClick={handleClickLinkDT}
                                        >
                                            <div className="floatbtnskew">
                                                MORE INFO
                                                <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                            </div>
                                        </div>
                                    
                                    </div>
                                    } */}


                        {
                        (selectedDragExpo.tickets_available
                            // && selectedParty?.price !== 999
                            // && selectedParty.price_type !== "more"
                            // && !selectedParty?.global
                            )
                        &&
                        <div className="floatingbuttoncont2">

                           

                            {/* <div className="btnsect1">
                                <div className="newpricehead">Price range</div>
                                <div className="newprice">{selectedParty.currency_symbol}{selectedParty.price} - $45</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLink}
                                >
                                    
                                        GET A TICKET
                                    
                                </div>
                            </div> */}

                            
                            {
                                selectedDragExpo.starting_price !== selectedDragExpo.price_type
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Price range</div>
                                <div className="newpricefree">{selectedDragExpo.currency_symbol}{selectedDragExpo.price_low} - {selectedDragExpo.price_high}</div>
                            </div>
                            }
                            {
                                selectedDragExpo.starting_price === selectedDragExpo.price_type
                                &&
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Tickets from</div>
                                <div className="newpricefree">{selectedDragExpo.currency_symbol}{selectedDragExpo.price_high}</div>
                            </div>
                            }
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLinkDT}
                                >
                                    
                                        TICKETS
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                </div>

                            </div>

                        </div>
                        }
                        
                       
                        {
                        selectedDragExpo.price_high === 0 && selectedDragExpo.tickets_available
                            
                        &&
                        
                        <div className="floatingbuttoncont2">

                            {/* <div className="sharebutton"
                            onClick={()=>shareParty(selectedParty?.name, selectedParty?.description, selectedParty?.website)}
                            >
                                <div className="floatbtnskew">
                                    <IonIcon icon={shareOutline} className="sharebtnicon" />
                                </div>
                            </div> */}

                            {/* <div className="mainwindowbtn"
                            onClick={handleClickLink}
                            >
                                <div className="floatbtnskew">
                                    MORE INFO
                                    <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                </div>
                            </div> */}

                            <div className="btnsect1free">
                                <div className="newpriceheadfree">This event is</div>
                                <div className="newpricefree">FREE</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLinkDT}
                                >
                                    
                                        INFO
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />

                                </div>

                            </div>

                            
                            
                        
                        </div>
                        }
                        

                        {
                        !selectedDragExpo.tickets_available
                            
                          
                        &&
                        
                        <div className="floatingbuttoncont2">

                            {/* <div className="sharebutton"
                            onClick={()=>shareParty(selectedParty?.name, selectedParty?.description, selectedParty?.website)}
                            >
                                <div className="floatbtnskew">
                                    <IonIcon icon={shareOutline} className="sharebtnicon" />
                                </div>
                            </div> */}

                            {/* <div className="mainwindowbtn"
                            onClick={handleClickLink}
                            >
                                <div className="floatbtnskew">
                                    MORE INFO
                                    <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                </div>
                            </div> */}

                            <div className="btnsect1free">
                                <div className="newpriceheadfree">This event is</div>
                                <div className="newpricesoldout">SOLD OUT</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtnred"
                                onClick={handleClickLinkDT}
                                >
                                    
                                        INFO
                                        {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrowrd" />

                                </div>

                            </div>

                            
                            
                        
                        </div>
                        }

                        </div>
                        <div className="spaceforbtn"></div>
                </div>               
            </IonContent>
            ) : (
                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                    <IonButtons
                    // slot="start"
                    >
                        <IonBackButton defaultHref="/" text="">
                        </IonBackButton>
                    </IonButtons>
                    </div>
                    <div className="innerwindowflex">
                        <div className="notfoundmsg">
                        This event has either finished or been removed. Please click the back button.
                        </div>
                        
                    </div>
                </div>
            )
        }
        </IonPage>
    )
}

export default DragTourWindow;