import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

// import { Share } from '@capacitor/share';

import { useStorage } from '../hooks/useStorage';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonModal,
    IonMenuButton
} from '@ionic/react';

import {
    shareOutline,
    heartSharp,
    heartOutline,
    arrowForwardOutline,
    closeOutline,
    alertCircle,
    alertCircleOutline,
    locationOutline,
    calendarOutline,
    boatOutline,
    chevronBack
} from 'ionicons/icons';

// import { Swiper, SwiperSlide } from 'swiper/react';

// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

// import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
import '../theme/spotcards.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';

// function numberWithCommas(x:any) {
//     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
//   }


const MarkerCruises: React.FC<{
    onClick: any;
    feature: any;
}> = ({
    onClick,
    feature }) => {

    return (
      <button onClick={onClick}
      className="marker">
            <div>{feature.properties.description}</div>
      </button>
    );
};


const CruiseWindow: React.FC<{
    isWeb: any;
    gridNo: any;
    cruises: any[];
    likedCruises: any;
    setLikedCruises: any;
    people: any;
    currentDateAndTime: any;
    handleIncrement: any;
    handleDecrement: any;
    handleIncrClick: any;
}> = (props) => {

    const selectedCruiseId = useParams<{ id: string }>().id;
    // const selectedCruise = props.cruises.find(cruise => cruise.id === +selectedCruiseId);
    const selectedCruise = props.cruises.find(cruise => cruise.id === selectedCruiseId);

    // const appZoom = selectedCruise.region === "French Polynesia" ? 8 : 4;
    //alert. not sure about the above.

    const [isWindowMapOpenCruises, setIsWindowMapOpenCruises] = useState<any>(false);
    const [isWindowMapLoadedCruises, setIsWindowMapLoadedCruises] = useState<any>(false);
    const [myMapCruises, setMyMapCruises] = useState<any>(null);

    // const [rootsContainingMarkersCruises, setRootsContainingMarkersCruises] = useState<any>(null);
    // const [myMapboxMarkersCruises, setMyMapboxMarkersCruises] = useState<any>(null);

    const modalMapCruises= useRef<HTMLIonModalElement>(null);
    const mapContainerCruises = useRef<any>(null);

    const handleLikeCruiseW = (cruisehash: any) => {
        // event.preventDefault();
        props.setLikedCruises((prev: any)=>[...prev, cruisehash]);
        props.handleIncrement("cruises", cruisehash);
      }
    
      const handleUnlikeCruiseW = (cruisehash: any) => {
        // event.preventDefault();
        props.setLikedCruises(props.likedCruises.filter((cruiselikedhash:any) => cruiselikedhash !== cruisehash));
        props.handleDecrement("cruises", cruisehash);
      }

      const handleClickLinkC = () => {
        window.open(selectedCruise.website, '_system', 'location=yes');
        props.handleIncrClick("cruises", selectedCruise.hash);
    }

    useEffect(() => {

        if (!mapContainerCruises.current) {
            return
        }

        const zoomLevel:number = selectedCruise?.zoom;

        const mapCruises = new mapboxgl.Map({
            container: mapContainerCruises.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            // center: selectedCruise.geojson_data.features[0].geometry.coordinates,
            center: [selectedCruise?.lng, selectedCruise?.lat],
            zoom: zoomLevel,
            attributionControl: false
        });

        setMyMapCruises(mapCruises);

        selectedCruise?.geojson_data.features.forEach((feature: any) => {
            const el = document.createElement("div");
            el.className = 'marker';
            const root = createRoot(el);
            root.render(
            <MarkerCruises onClick={()=>mapCruises.flyTo({center: feature.geometry.coordinates, zoom: selectedCruise?.zoom_marker})} feature={feature} />);

            // Create a Mapbox Marker at our new DOM node
            new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .addTo(mapCruises);

        });

        return () => mapCruises.remove();

    },[isWindowMapLoadedCruises]);


    const handleDismissWindowMapModalCruises = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because...
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedCruises(false);
        setIsWindowMapOpenCruises(false);
    }


    return(
        <IonPage>
            {
                selectedCruise ? (
                <IonContent>
                    <IonModal
                    ref={modalMapCruises}
                    className="windowmapmodalC"
                    isOpen={isWindowMapOpenCruises}
                    onDidDismiss={handleDismissWindowMapModalCruises}
                    onDidPresent={() => setIsWindowMapLoadedCruises(true)}
                    >
                        <div className="mapcontainer">
                            <div ref={mapContainerCruises} className="mapcontainerinner">

                                {/* <div className="cruisemapcross">
                                    <div className="cruisemapcirc"
                                    onClick={handleDismissWindowMapModalCruises}
                                    >
                                        <IonIcon icon={closeOutline} className="cruisemapicon"/>
                                    </div>
                                </div> */}
                    
                                <div className="cruisemapdestcont">
                                    <IonContent className="cruisemapdestbox">
                                                    <div
                                                    className="cruisemapspotcard"
                                                    // key={index}
                                                    onClick={()=>myMapCruises.flyTo({center: [selectedCruise.other_destinations[0][3], selectedCruise.other_destinations[0][4]], zoom: selectedCruise.zoom_marker})}
                                                    >   
                                                        <div className="cruisemapspotfirstsection">
                                                            {/* <div className="BCcardflexboxleftmapviewC"> */}
                                                                <div className="cruisemapdestcirc">1</div>
                                                            {/* </div> */}
                                                        </div>
                                                
                                                        <div className="cruisemapspotmainsection">
                                                            <div className="cruisemapspotmainsectinner">
                                                                <div className="cruisemapspotmainsectinnert">{selectedCruise.other_destinations[0][0]}{", "}{selectedCruise.other_destinations[0][1] === "US" ? "United States" : selectedCruise.other_destinations[0][1]}</div>
                                                                
                                                                {/* <div className="cruisemapspotmainsectinnerb">Arrive&nbsp;<span className="cruisemapfont">{destination[5]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{destination[7]}</span></div> */}
                                                                <div className="cruisemapspotmainsectinnerb">Departs&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[0][6]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[0][8]}</span></div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                        {
                                        selectedCruise.other_destinations.slice(1,-1).map((destination: any, index: any) => {
                                            return <div
                                                    className="cruisemapspotcard"
                                                    key={index}
                                                    onClick={()=>myMapCruises.flyTo({center: [destination[3], destination[4]], zoom: selectedCruise.zoom_marker})}
                                                    >   
                                                        <div className="cruisemapspotfirstsection">
                                                            {/* <div className="BCcardflexboxleftmapviewC"> */}
                                                                <div className="cruisemapdestcirc">{index + 2}</div>
                                                            {/* </div> */}
                                                        </div>
                                                
                                                        <div className="cruisemapspotmainsection">
                                                            <div className="cruisemapspotmainsectinner">
                                                                <div className="cruisemapspotmainsectinnert">{destination[0]}{", "}{destination[1] === "US" ? "United States" : destination[1]}</div>
                                                                
                                                                <div className="cruisemapspotmainsectinnerb">Arrives&nbsp;<span className="cruisemapfont">{destination[5]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{destination[7]}</span></div>
                                                                <div className="cruisemapspotmainsectinnerb">Departs&nbsp;<span className="cruisemapfont">{destination[6]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{destination[8]}</span></div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                        })
                                        }
                                                    <div
                                                    className="cruisemapspotcard"
                                                    // key={index}
                                                    onClick={()=>myMapCruises.flyTo({center: [selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][3], selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][4]], zoom: selectedCruise.zoom_marker})}
                                                    >   
                                                        <div className="cruisemapspotfirstsection">
                                                            {/* <div className="BCcardflexboxleftmapviewC"> */}
                                                                <div className="cruisemapdestcirc">{selectedCruise.other_destinations.length}</div>
                                                            {/* </div> */}
                                                        </div>
                                                
                                                        <div className="cruisemapspotmainsection">
                                                            <div className="cruisemapspotmainsectinner">
                                                                <div className="cruisemapspotmainsectinnert">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][0]}{", "}{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][1] === "US" ? "United States" : selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][1]}</div>
                                                                
                                                                <div className="cruisemapspotmainsectinnerb">Arrives&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][5]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][7]}</span></div>
                                                                {/* <div className="cruisemapspotmainsectinnerb">Depart&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[0][6]}</span>&nbsp;at&nbsp;<span className="cruisemapfont">{selectedCruise.other_destinations[0][8]}</span></div> */}
                                                            </div>
                                                        </div>
                                                
                                                    </div>         
                                    </IonContent>
                                </div>
                            </div>
                        </div>
                    </IonModal>


                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="ionbackbuttoncontoth">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                        </div>
                        {/* review first conditionals in these when i revise approach to time with cruises */}
                        {
                        // selectedCruise.daterealstart > props.currentDateAndTime
                        // &&
                        // why did i have a time based conditional for likes?
                        
                        !props.isWeb
                        &&
                        props.likedCruises.filter((likedcruisehash: any) => likedcruisehash === selectedCruise.hash).length === 0
                        &&
                        <div
                        className="windowheartcont"
                        // onClick={()=>props.setLikedCruises((prev: any)=>[...prev, selectedCruise.id])}
                        onClick={() => handleLikeCruiseW(selectedCruise.hash)}
                        >
                            <IonIcon icon={heartOutline} color="danger" className="windowheart" />
                        </div>
                        }
                        {
                        // selectedCruise.daterealstart > props.currentDateAndTime
                        // &&
                        // why did i have a time based conditional for likes?

                        !props.isWeb
                        &&
                        props.likedCruises.filter((likedcruisehash: any) => likedcruisehash === selectedCruise.hash).length > 0
                        &&
                        <div
                        className="windowheartcont"
                        // onClick={()=>props.setLikedCruises(props.likedCruises.filter((likedcruiseid: any) => likedcruiseid !== selectedCruise.id))}
                        onClick={() => handleUnlikeCruiseW(selectedCruise.hash)}
                        >
                            <IonIcon icon={heartSharp} color="danger" className="windowheart" />
                            {/* <div className="windowheartlit"></div> */}
                        </div>
                        }
                        {/* {
                        selectedCruise.daterealstart <= props.currentDateAndTime
                        &&
                        <div
                        className="windowsectionlivesignalcontainer">
                            <div className="listviewcardblinkingtext">LIVE</div>
                        </div>
                        } */}

                        <div className="windowtopcont">
                            {
                                selectedCruise.image_src
                                &&
                                <img className="windowimgbox" src={selectedCruise.image_src} alt=""
                                onError={(e: any) => e.target.src = selectedCruise.image_url}
                                ></img>

                            }
                            {
                                !selectedCruise.image_src
                                &&
                                <img className="windowimgbox" src={selectedCruise.image_url} alt=""
                                ></img>

                            }
                            <div className="windowimggrad">
                                <div className="windowtitlebox">
                                    {/* <div className="windowtitleshortcru">
                                        {selectedCruise.name}
                                    </div>
                                    <div className="titleboxright">
                                        {
                                            selectedCruise.tickets_available
                                            &&
                                            <div className="titlerightcr">
                                      
                                                <div className="titlerightfrom">from</div>
                                                <div className="titlerightmarg">{selectedCruise?.currency_symbol}{selectedCruise?.starting_price}</div>
                                            </div>
                                        }
                                        {
                                            !selectedCruise.tickets_available
                                            &&
                                            <div className="titlerightsoldout">
                                      
                                                <div className="">SOLD</div>
                                                <div className="">OUT</div>
                                            </div>
                                        }
                                        
                                    </div> */}
                                    <div className="windowtitlefull">
                                       
                                            
                                              
                                        {selectedCruise.name}
                                      

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="windowmainsetwidth">

                        <div className="windowdatetimecontf">
                           
                           <div className="datetimeboxf">
                               <IonIcon icon={locationOutline} className="datetimeicon"></IonIcon>
                               <div className="datetimetext">
                                       {selectedCruise.region}
                               </div>
                           </div>
                       
                       
                           <div className="datetimeboxf">
                               <IonIcon icon={calendarOutline} className="datetimeicon"/>
                               <div className="datetimetext">
                                  
                                   
                                   {selectedCruise.datestring}
                                   {" "}{selectedCruise.finish_year}
                               </div>
                           </div>
                        {                 
                        selectedCruise.boat_name
                        &&
                           <div className="datetimeboxf">
                               <IonIcon icon={boatOutline} className="datetimeicon"/>
                               <div className="datetimetext">
                                  
                                 {selectedCruise.boat_name}
                               </div>
                           </div>
                           }
               
                        </div>


                        {
                            (selectedCruise.description !== "")
                            &&
                            <div className="windowmainboxstd">
                                <div className="windowmainheadingstd">
                                    About
                                </div>   
                                <div className="windowmaintextboxstd">
                                    {selectedCruise.description}
                                </div>
                                {
                                selectedCruise.extra_info !== ""
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedCruise.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedCruise.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>
                                }
                            </div>
                            }

                            <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                selectedCruise.organiser !== ""
                                &&
                                <div
                                // style={selectedCruise?.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                style={{width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {selectedCruise.organiser}
                                    </div>
                                </div>
                                }
                                {/* {
                                selectedCruise?.ticket_provider !== ""
                                &&
                                <div
                                style={selectedCruise.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedCruise.ticket_provider}
                                    </div>
                                </div>
                                } */}
                            </div>



                        





                            <div className="itinplusmapheadercont">
                                <div className="windowmainheadingflex">
                                    {/* v9 has specific style but ive used generic for the moment */}
                                        Itinerary
                                </div>
                                <div className="viewmapbtnpurp"
                                onClick={() => setIsWindowMapOpenCruises(true)}
                                >MAP</div>
                            </div>
                            <div className="itinerarybox">
                                <div className="itineraryflexbox">
                                    <div className="itincirccont">
                                        <div className="itincirc circinv">1</div>
                                    </div>
                                </div> 
                                <div className="itineraryflexbox">
                                    <div className="itinlinecont">
                                        <div className="itinlineleft"></div>
                                        <div className="itinlineright"></div>
                                    </div>
                                    <div className="itinerarytextbox">
                                        <div className="itinerarymiddletext midtextextraoth">{selectedCruise.other_destinations[0][0]}{", "}{selectedCruise.other_destinations[0][1]}</div>
                                        
                                        <div className="itinerarybottomtext">Departs&nbsp;<span className="bottextkeyword">{selectedCruise.other_destinations[0][6]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{selectedCruise.other_destinations[0][8]}</span></div>
                                    </div>
                                </div> 

                                {
                                selectedCruise.other_destinations.slice(1,-1).map((destination: any, index: number) => {
                                    return <div key={index}>
                                                <div className="itineraryflexbox">
                                                    <div className="itincirccont">
                                                        <div className="itincirc circ">{index + 2}</div>
                                                    </div>
                                                    {/* <div className="itinerarytextbox"></div> */}
                                                </div> 
                                                <div className="itineraryflexbox">
                                                    <div className="itinlinecont">
                                                        <div className="itinlineleft"></div>
                                                        <div className="itinlineright"></div>
                                                    </div>
                                                    <div className="itinerarytextbox">
                                                        <div className="itinerarymiddletext"><span className="itinmidtextinner">{destination[0]}{", "}{destination[1]}</span></div>

                                                        <div className="itinerarybottomtext midtextextraoth">Arrives&nbsp;<span className="bottextkeyword">{destination[5]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{destination[7]}</span></div>
                                                        <div className="itinerarybottomtext">Departs&nbsp;<span className="bottextkeyword">{destination[6]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{destination[8]}</span></div>
                                                    </div>
                                                </div> 
                                        </div>
                                })
                                }
                                    

                                <div className="itineraryflexbox">
                                    <div className="itincirccont">
                                        <div className="itincirc circinv">{selectedCruise.other_destinations.length}</div>
                                    </div>
                                </div> 
                                <div className="itineraryflexbox">
                                    <div className="itinlinecont">
                                    </div>
                                    <div className="itinerarytextbox">
                                        <div className="itinerarymiddletext midtextextraoth">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][0]}{", "}{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][1]}</div>

                                        <div className="itinerarybottomtext">Arrives&nbsp;<span className="bottextkeyword">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][5]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{selectedCruise.other_destinations[selectedCruise.other_destinations.length-1][7]}</span></div>

                                    </div>
                                </div> 


                            </div>

                            <div className="itinminuspad"></div>

                            
                            {/* below entertainers was fine but not having for the moment */}

                            {/* {
                            selectedCruise.entertainers !== ""
                            &&
                            props.people.filter((person: any) => selectedCruise.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Featured entertainers
                            </div>
                            } */}
                        

{/* theres now no reason to end set width below. but keeping because ill probably go back to having entertainers. */}
                        </div>

                            {/* {
                            selectedCruise?.entertainers !== ""
                            &&
                          
                            <div className="gridviewgridBC mgab">
                                {props.people.filter((person: any) => selectedCruise.entertainers.some((entertainer: any) => entertainer === person.name))
                                .map((person: any, index: any) => {
                                    return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                                                <div className="gridviewcardstd mgbel">
                                                    {
                                                        person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_src} alt=""
                                                        onError={(e: any) => e.target.src = person.image_url}

                                                        />

                                                    }
                                                    {
                                                        !person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_url} alt=""
                                                        />

                                                    }
                                                    
                                                    <div className="gridviewcardimagecoverppl">
                                                    {
                                                            person.type[0] === "Drag queen"
                                                            &&
                                                            <div className="newtypecont">Drag</div>
                                                            }      
                                                            {
                                                            person.type[0] === "DJ"
                                                            &&
                                                            <div className="newtypecont">DJ</div>
                                                            }         
                                                            {
                                                            person.type[0] === "Singer"
                                                            &&
                                                            <div className="newtypecont">Singer</div>
                                                            }          
                                                            {
                                                            person.type[0] === "Other"
                                                            &&
                                                            <div className="newtypecont">Dancer</div>
                                                    }
                                                        
                                                        
                                                        <div className="gridviewbottomcontainer19">               
                                                
                                                               
                                                                    <div className="gridviewtitlefull">
                                                                        {person.name}
                                                                    </div>
                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                    })}
                                    
                                </div>
                                } */}
                                
                            

                        <div className="windowmainsetwidth">
                            

                           
                            {
                        (selectedCruise.tickets_available
                          
                            )
                        &&
                        <div className="floatingbuttoncont2">

                           

                        

                            
                           
                            <div className="btnsect1free">
                                <div className="newpriceheadfree">Tickets from</div>
                                <div className="newpricefree">US{selectedCruise.currency_symbol}{selectedCruise.starting_price.toLocaleString()}</div>
                            </div>
                            
                            <div className="btnsect2">
                                <div className="mainwindowbtngreen"
                                onClick={handleClickLinkC}
                                >
                                    
                                        TICKETS
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />

                                </div>

                            </div>

                        </div>
                        }
                        
                       

                        

                        {
                        !selectedCruise.tickets_available
                            
                          
                        &&
                        
                        <div className="floatingbuttoncont2">

                         

                            <div className="btnsect1free">
                                <div className="newpriceheadfree">This cruise is</div>
                                <div className="newpricesoldout">SOLD OUT</div>
                            </div>
                            <div className="btnsect2">
                                <div className="mainwindowbtnred"
                                onClick={handleClickLinkC}
                                >
                                    
                                        INFO
                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrowrd" />

                                </div>

                            </div>

                            
                            
                        
                        </div>
                        }
                        </div>
                            <div className="spaceforbtn"></div>
                        
                    </div>               
                </IonContent>
                ) : (
                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonBackButton defaultHref="/" text="">
                            </IonBackButton>
                        </IonButtons>
                        </div>
                        <div className="innerwindowflex">
                            <div className="notfoundmsg">
                            This cruise has either finished or been removed. Please click the back button.
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </IonPage>
    )
}

export default CruiseWindow;