// need to add live sign somewhere
// do you need question marks after selectedParty..ive taken like half off..
// need to add sold out case. whereever tickets available is false. (whether free or not)
// dont need swiper anymore. also remove css from swipers.css

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';
// to work with typescript had to do npm install -D @types/mapbox-gl

// import { Share } from '@capacitor/share';

import {
    IonPage,
    IonContent,
    // IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    // IonModal,
    IonMenuButton
} from '@ionic/react';

import { calendarOutline,
    // timeOutline, mapOutline, shareOutline,
    heartSharp, heartOutline,
    // locationSharp, pricetagOutline, pricetag, cashOutline, arrowForwardCircleOutline,
    arrowForwardOutline,
    // alertCircle, playCircleOutline,
    locationOutline,
    // chevronForwardOutline,
    alertCircleOutline,
    pricetagsOutline,
    chevronBack,
    // logoInstagram
} from 'ionicons/icons';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';



// import mapimage from '../images/buttonroundmap.png';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const CircWind: React.FC<{
    isWeb: any;
    festivalsCirc: any;
    parties: any[];
    barsAndClubs: any[];
    likedParties: any;
    setLikedParties: any;
    // people: any;
    peopleOth: any;
    currentDateAndTime: any;
    handleIncrement: any;
    handleDecrement: any;
    handleIncrClick: any;
    // handleDecrClick: any;
    cityListN: any;
    changeCityLogic: any;
    citySelected: any;
}> = (props) => {

    const selectedPartyId = useParams<{ id: string }>().id;
    const selectedParty = props.festivalsCirc.find((fest:any) => fest.id === selectedPartyId);

    const [isWindowMapOpenCF, setIsWindowMapOpenCF] = useState<any>(false);
    const [isWindowMapLoadedCF, setIsWindowMapLoadedCF] = useState<any>(false);
   
    const modalMapCF= useRef<HTMLIonModalElement>(null);
    const mapContainerCF = useRef<any>(null);
    
    const handleLikePartyCF = (partyhash: any) => {
        // event.preventDefault();
        props.setLikedParties((prev: any)=>[...prev, partyhash]);
        props.handleIncrement("festivals", partyhash);
      }
    
      const handleUnlikePartyCF = (partyhash: any) => {
        // event.preventDefault();
        props.setLikedParties(props.likedParties.filter((partylikedhash:any) => partylikedhash !== partyhash));
        props.handleDecrement("festivals", partyhash);
      }

    const handleClickLinkCF = () => {
        window.open(selectedParty.website, '_system', 'location=yes');
        props.handleIncrClick("festivals", selectedParty.hash);
    }

    const resetCity = (citycl:any) => {
        if (citycl === props.citySelected.city) {
            return;
        }
        props.changeCityLogic(citycl);
    }


    useEffect(() => {

        if (!mapContainerCF.current) {
            return
        }

        const mapP = new mapboxgl.Map({
            container: mapContainerCF.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedParty?.lng, selectedParty?.lat],
            zoom: 8,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([selectedParty?.lng, selectedParty?.lat]).addTo(mapP);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapP(mapP);

        return () => mapP.remove();

    },[isWindowMapLoadedCF]);

    const handleDismissWindowMapModalCF = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because... (update: i commented it out)
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedCF(false);
        setIsWindowMapOpenCF(false);
    }
    

    useEffect(() => {
        console.log(props.cityListN, 'city list fuck')
    })

    return(
        <IonPage>
            {
                selectedParty ? (
            <IonContent>
                {/* <IonModal
                ref={modalMapCF}
                className="windowmapmodal"
                isOpen={isWindowMapOpenCF}
                onDidDismiss={handleDismissWindowMapModalCF}
                onDidPresent={() => setIsWindowMapLoadedCF(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerCF} className="mapcontainerinner" />
                    </div>
                </IonModal> */}

                <div className="windowcontainerwithgrad">
                    <div className="ionbackbuttoncont">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="ionbackbuttoncontoth">
                        <IonButtons
                       
                        >
                            <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                            </IonBackButton>
                        </IonButtons>
                    </div>
                    <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                    </div>
                    {
                        
                            !props.isWeb
                            &&
                    props.likedParties.filter((likedpartyhash: any) => likedpartyhash === selectedParty.hash).length === 0
                    &&
                    <div
                    className="windowheartcont"
                    // onClick={()=>props.setLikedParties((prev: any)=>[...prev, selectedParty?.hash])}
                    onClick={() => handleLikePartyCF(selectedParty.hash)}
                    >
                        <IonIcon icon={heartOutline} color="danger" className="windowheart" />
                    </div>
                    }
                    {
                        
                            !props.isWeb
                            &&
                    props.likedParties.filter((likedpartyhash: any) => likedpartyhash === selectedParty.hash).length > 0
                    &&
                    <div
                    className="windowheartcont"
                    // onClick={()=>props.setLikedParties(props.likedParties.filter((likedpartyhash: any) => likedpartyhash !== selectedParty?.hash))}
                    onClick={() => handleUnlikePartyCF(selectedParty.hash)}
                    >
                        <IonIcon icon={heartSharp} color="danger" className="windowheart" />
                        {/* <div className="windowheartlit"></div> */}
                    </div>
                    }
                    <div className="windowtopcont">
                        {
                            selectedParty.image_src
                            &&
                            <img className="windowimgbox" src={selectedParty.image_src}
                            onError={(e: any) => e.target.src = selectedParty.image_url}
                            ></img>
                        }
                        {
                            !selectedParty.image_src
                            &&
                            <img className="windowimgbox" src={selectedParty.image_url}
                            ></img>
                        }
                        <div className="windowimggrad">
                            
                                <div className="windowtitlebox">

                                        {/* <div
                                        className="livewindowboxcity">
                                            <div className="blinkingtextcity">{selectedParty?.city}{", "}{selectedParty?.country}</div>
                                        </div> */}

                                

                                

                                
                                    <div className="windowtitlefull">
                                        {selectedParty.name}
                                    </div>
                                    
                                   
                                
                                    
                                    {/* <div className="titleboxright">
                                        <div className="titlerightcity">
                                            <div>{selectedParty?.city}</div>
                                        </div>
                                    </div> */}
                                   
                                </div>
                            
                            
                            
                        </div>
                    </div>

                    
                    {/* why do i need party to have festparam for below... cant i just use the festparam of the festival? */}
                    <div className="windowmainsetwidth">

                                {
                                   
                                    // props.cityListN.filter((city:any) => city === selectedParty?.city).length > 0
                                    props.parties.filter((party:any) => party.festival === selectedParty.name).length > 0
                                    &&
                                    <div className="festbtncont2">
                                        <div className="intxx">For a full list of parties and events happening during {selectedParty.name}, visit the {selectedParty.city} Events page.</div>
                                        <div className="festbtnoth"
                                        onClick={() => resetCity(selectedParty.city)}
                                        >
                                            <Link to={`/parties-fest/${selectedParty.festparam}`} style={{ display: 'contents' }}>
                                            {/* <div className="festbtntxt">GO TO FESTIVAL PAGE</div>
                                            <IonIcon icon={chevronForwardOutline} className="clickawayarrow2" /> */}
                                                    <div className="mapspotarrowcontoth">
                                                        <IonIcon icon={arrowForwardOutline} className="mapspotarrowoth" />
                                                    </div>
                                        
                                            </Link>
                                        </div>
                                        
                                    </div>
                                }

                                {/* ALERT reinstate above later. for pride and circuit festivals? */}
                                

                                



                        
                                         

                        <div className="windowdatetimecontf">
                            {/* <div className="datetimebox pdri">
                                <IonIcon icon={locationOutline} className="datetimeiconme" />
                                <div className="citybox">
                                    <div className="datetimetext2">
                                            {selectedParty?.city}, {selectedParty?.country}
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="datetimebox">
                                <IonIcon icon={calendarOutline} className="datetimeicon" />
                                <div className="timebox">
                                    <div className="datetimetext2">
                                        {selectedParty?.start_day.slice(0,3)}{", "}{selectedParty?.start_date}{" "}{selectedParty?.start_month.slice(0,3)}{selectedParty?.start_year !== selectedParty?.finish_year ? ` ${selectedParty?.start_year}` : ""}{" - "}
                                        <br/>{selectedParty?.finish_day.slice(0,3)}{", "}{selectedParty?.finish_date}{" "}{selectedParty?.finish_month.slice(0,3)}{" "}{selectedParty?.finish_year}
                                    </div>
                                </div>
                            </div>                   */}
                       
                                {/* <IonIcon icon={calendarOutline} className="datetimeicon"/> */}
                                {/* <img className="neoncal" src={neoncal} alt="" /> */}
                                
                            
                            
                                <div className="datetimeboxf">
                                    <IonIcon icon={calendarOutline} className="datetimeicon"/>
                                    <div className="datetimetext">
                                        {/* {selectedParty?.start_day.slice(0,3)}{", "} */}
                                        {/* {selectedParty?.start_date}{" "}{selectedParty?.start_month.slice(0,3)}{selectedParty?.start_year !== selectedParty?.finish_year ? ` ${selectedParty?.start_year}` : ""}{" - "} */}
                                        {/* <br/> */}
                                        {/* {selectedParty?.finish_day.slice(0,3)}{", "} */}
                                        {/* {selectedParty?.finish_date}{" "}{selectedParty?.finish_month.slice(0,3)}{" "}{selectedParty?.finish_year} */}
                                        
                                        
                                        {selectedParty.datestring}
                                        {" "}{selectedParty.finish_year}
                                    </div>
                                </div>

                                <div className="datetimeboxf">
                                    <IonIcon icon={locationOutline} className="datetimeicon"></IonIcon>
                                    <div className="datetimetext">
                                            {selectedParty.city}{selectedParty.country !== "" ? `, ${selectedParty.country}` : ""}
                                    </div>
                                </div>

                                {
                                    selectedParty.price_info
                                    &&
                                <div className="datetimeboxf">
                                    <IonIcon icon={pricetagsOutline} className="datetimeicon"/>
                                    <div className="datetimetext">
                                     
                                        
                                        
                                        {selectedParty.price_info}
                                    
                                    </div>
                                </div>
                                }
                                
                    
                        </div>
                       
                        
                        {
                        (selectedParty.description !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>   
                            <div className="windowmaintextboxstd">
                                {selectedParty.description}
                            </div>
                                {/* {
                            selectedParty?.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{selectedParty?.extra_info}</div>
                            </div>
                            } */}
                            {
                                (selectedParty.extra_info !== "")
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedParty.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedParty.extra_info}
                                        </div>
                                        }
                                        
                                        
                                    </div>

                            }
                        </div>
                        }


                            {/* {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue).length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Venue
                            </div>
                            } */}

                        </div>

                        {/* {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue).length > 0
                            &&
                            <div className="gridviewgridBC">
                                {
                                    props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue)
                                    .map((venue: any, index: number) => {
                                        return <div key={index} className="gridviewcardstd">
                                            <Link to={`/${venue.barorclub ? "barsandclubs" : "saunas"}/${venue.id}`} style={{ display: 'contents' }}>
                                        {
                                        (venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_src} alt="" 
                                        onError={(e: any) => e.target.src = venue.image_url}
                                        />
                                        }
                                        {
                                        !(venue.image_src)
                                        &&
                                        <img className="gridviewcardimage" src={venue.image_url} alt="" 
                                        />
                                        }
                                        <div className="gridviewcardimagecover">
                                           
                                            <div className="gridviewbottomcontainermid">               
                                                <div className="gridviewtitlecontainerfull">
                                                    {
                                                        venue.name_short === ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name}</div>
                                                    }
                                                    {
                                                        venue.name_short !== ""
                                                        &&
                                                        <div className="gridviewtitlefull">{venue.name_short}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Link>   
                                        </div>

                                    })
                                }
                            </div>
                            } */}

                          

                            <div className="windowmainsetwidth">

                            {/* {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue).length === 0
                            &&
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            }
                            {
                            props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue).length === 0
                            &&
                            <div className="windowmainvenuecardbox">
                                <div className="windowvenueleft">      
                                    {
                                    selectedParty?.venue !== ""
                                    &&
                                    <div>
                                        <span className="addresshasvenuename">{selectedParty?.venue}</span>
                                    </div>
                                    }
                                    {selectedParty?.suite_number === "" ? "" : <div>{selectedParty?.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {selectedParty?.street_number === "" ? <div>{selectedParty?.street_name}</div> : <div className="limitaddresstooneline">{selectedParty?.street_number}{" "}{selectedParty?.street_name}</div>}
                                    </div>
                                    <div>{selectedParty?.suburb} {selectedParty?.postcode}</div>       
                                </div>
                                <div className="windowvenueright">  
                                    <img
                                    src={mapimage}
                                    alt=""
                                    className="windowvenueimg" 
                                    onClick={() => setIsWindowMapOpenCF(true)}
                                    />
                                </div>
                            </div>
                            }     */}

                        
                        {/* {
                                props.parties.filter((party:any) => party.circuit === selectedParty?.name).length > 0
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                            Parties
                                    </div>
                                   
                                    <div className="pricingflexboxcirccontainer">
                                        {
                                            props.parties.filter((party:any) => party.circuit === selectedParty?.name)
                                            .map((option: any, index: number) => {
                                                return <div key={index} className="pricingoptionscircname">{option.name}
                                                        </div>
                                                        
                                        
                                            })
                                        }

                                    </div>

                                </div>
                            } */}
                      

                        

                    {/* the below entertainer section works but i think given you can click through to the festival and then the parties having entertainers especially with pride festivals isn't really necessary. */}
                        
                        {/* {
                        (selectedParty?.entertainers !== "")
                        &&
                        props.peopleOth.filter((person: any) => selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                        &&
                        <div className="windowmainheadingstd">
                            Featured entertainers
                        </div>
                        } */}

                        
                    </div>

                    {/* {
                    (selectedParty?.entertainers !== "")
                    &&
                    props.peopleOth.filter((person: any) => selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                    &&
                    <div className="gridviewgridBC mgab">
                        {props.peopleOth.filter((person: any) => selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name))
                        .map((person: any, index: any) => {
                            return <Link key={index} to={`/people/${person.id}`} style={{ display: 'contents' }}>
                                        <div className="gridviewcardstd mgbel">
                                            {
                                                person.image_src
                                                &&
                                                <img className="gridviewcardimage" src={person.image_src} alt=""
                                                onError={(e: any) => e.target.src = person.image_url}
                                                />
                                            }
                                            {
                                                !person.image_src
                                                &&
                                                <img className="gridviewcardimage" src={person.image_url} alt="" />
                                            }
                                            
                                            <div className="gridviewcardimagecoverppl">
                                                {
                                                person.type[0] === "Drag queen"
                                                &&
                                                <div className="newtypecont">Drag</div>
                                                }      
                                                {
                                                person.type[0] === "DJ"
                                                &&
                                                <div className="newtypecont">DJ</div>
                                                }         
                                                {
                                                person.type[0] === "Singer"
                                                &&
                                                <div className="newtypecont">Singer</div>
                                                }          
                                                {
                                                person.type[0] === "Other"
                                                &&
                                                <div className="newtypecont">Dancer</div>
                                                }


                                              
                                                
                                                
                                                <div className="gridviewbottomcontainer19">               
                                        
                                                        
                                                            <div className="gridviewtitlefull">
                                                                {person.name}
                                                            </div>
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                        })}
                    </div>
                    } */}

                   

                    <div className="windowmainsetwidth">
                        <div className="windowdatetimecont">
                            {/* using the above because same properties and values */}
                            {
                            selectedParty.organiser !== ""
                            &&
                            <div
                            style={selectedParty.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                            >
                                <div className="windowmainheadingstd">
                                    Organiser
                                </div>
                                <div className="windowmaintextboxstd extrapadorg">
                                    {selectedParty.organiser}
                                </div>
                            </div>
                            }
                            {/* {
                            selectedParty?.ticket_provider !== ""
                            &&
                            <div
                            style={selectedParty?.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                            >
                                <div className="windowmainheadingstd">
                                    Ticket vendor
                                </div>
                                <div className="windowmaintextboxstd">
                                    {selectedParty?.ticket_provider}
                                </div>
                            </div>
                            } */}
                        </div>

                        {/* <div className="windowmainboxstd">
                            {
                            (selectedParty?.instagram !== "")
                            &&
                            <div className="wmcont">
                                <div className="wmflex">
                                    <div className="wmtxt">Instagram</div>
                                    <div className="wmtxtb">@{selectedParty.instagram}</div>
                                </div>
                                    
                                <div className="wmiconinsta"
                                onClick={()=>window.open(`https://www.instagram.com/${selectedParty.instagram}`, '_system', 'location=yes')}
                                >
                                        <IonIcon icon={logoInstagram} className="insticn" />
                                </div>
                            </div>
                            }
                            
                        </div> */}

                       
                        {/* <div className="floatingbuttoncont">

                            
                        
                            <div className="mainwindowbtn"
                            onClick={handleClickLinkCF}
                            >
                                <div className="floatbtnskew">
                                    GET TICKETS
                                    <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                </div>
                            </div>

                        </div> */}

                                <div className="floatingbuttoncontonething">

                                                        

                                <div className="btnsectonething">                       
                                        <div className="mainwindowbtngreen"
                                            onClick={handleClickLinkCF}
                                            >
                                            
                                                MORE INFO
                                                <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                            
                                        </div>
                                </div>






                                </div>
                        
                        
                        

                        
                        
                    </div>
                    <div className="spaceforbtn"></div>
                </div>               
            </IonContent>
                ) : (
                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonBackButton defaultHref="/" text="">
                            </IonBackButton>
                        </IonButtons>
                        </div>
                        <div className="innerwindowflex">
                            <div className="notfoundmsg">
                            This festival has either finished or been removed. Please click the back button.
                            </div>
                            {/* could be pride or circuit festival */}
                        </div>
                    </div>
                )
            }
        </IonPage>
    )
}

export default CircWind;