import { useState, useEffect, useCallback } from 'react';

import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';


import { Capacitor } from '@capacitor/core';


import { Filesystem, Directory } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';

import { isPlatform } from '@ionic/core';

// onesignal
import OneSignal from 'onesignal-cordova-plugin';

import { Image } from '../types/Image';

// import { debounce } from './utilities';



const ALLIMAGES_KEY = 'all-images';
const PARTIESLIKED_KEY = 'parties-liked';
const DRAGEXPOSLIKED_KEY = 'dragexpos-liked';
const BARSANDCLUBSLIKED_KEY = 'barsandclubs-liked';
const PEOPLELIKED_KEY = 'people-liked';
const CRUISESLIKED_KEY = 'cruises-liked';
const CIRCFESTSLIKED_KEY = 'circfests-liked';
const NOTIFICATIONSVIEWED_KEY = 'notifications-viewed';
const DEFCITY_KEY = 'def-city';
const CITIESNOTIFS_KEY = 'cities-notifs';
const PROMOSVIEWED_KEY = 'promos-viewed';
const CLEANUP_DONE_KEY = 'cleanup-done';


// const BUFFER_SPACE = 20 * 1024 * 1024;



export function useStorage() {

    const [store, setStore] = useState<Storage>();

    const [storedImages, setStoredImages] = useState<Image[]>([]);
    const [likedParties, setLikedParties] = useState<any[]>([]);
    const [likedDragExpos, setLikedDragExpos] = useState<any[]>([]);
    const [likedBarsAndClubs, setLikedBarsAndClubs] = useState<any[]>([]);
    const [likedPeople, setLikedPeople] = useState<any[]>([]);
    const [likedCruises, setLikedCruises] = useState<any[]>([]);
    const [likedCircFests, setLikedCircFests] = useState<any[]>([]);
    const [viewedNotifications, setViewedNotifications] = useState<any[]>([]);
    const [defCity, setDefCity] = useState<any>("");
    const [citiesNotifs, setCitiesNotifs] = useState<any[]>([]);
    const [viewedPromos, setViewedPromos] = useState<any[]>([]);
    const [storeIsLoaded, setStoreIsLoaded] = useState(false);
    const [imagesAreLoaded, setImagesAreLoaded] = useState(false);
    const [viewedNotificationsAreLoaded, setViewedNotificationsAreLoaded] = useState(false);
    const [defCityIsLoaded, setDefCityIsLoaded] = useState(false);
    const [cityNotifsAreLoaded, setCityNotifsAreLoaded] = useState(false);
    const [viewedPromosAreLoaded, setViewedPromosAreLoaded] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProcessingImages, setIsProcessingImages] = useState(false);


    const cleanupOldVersionData = async (store:any, savedImages:any) => {
      try {
   

          console.log(imagesAreLoaded, 'images are loaded 2')
  
          for (let i = 0; i < savedImages.length; i++) {
            await Filesystem.deleteFile({
              path: savedImages[i].fileName,
              directory: Directory.Data,
            });
          }
          await store.set(ALLIMAGES_KEY, []);
          // Add any other cleanup tasks here
          console.log('Completed cleanupOldVersionData');

      } catch (error) {
        console.error(`Failed to delete image`, error);

      }
      
    };

    
    useEffect(() => {
      const initStorage = async () => {

        // Create a connection to the Storage and save the reference
        const newStore = new Storage({
          name: 'ionicstorage',
          driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        });
        await newStore.defineDriver(CordovaSQLiteDriver);
        const store = await newStore.create();
        setStore(store);
        console.log(store, 'New connection to store.');

        const cleanupDone = await store.get(CLEANUP_DONE_KEY);
        const savedImages = await store.get(ALLIMAGES_KEY) || [];
        
        if (!cleanupDone) {
          if (savedImages.length !== 0) {
            await cleanupOldVersionData(store, savedImages);
          }
          await store.set(CLEANUP_DONE_KEY, true);
        }
        
        const updatedSavedImages = await store.get(ALLIMAGES_KEY) || [];
        console.log('Updated saved images after cleanup:', updatedSavedImages);
  
        if (updatedSavedImages.length === 0) {
            setFirstLoad(true);
        } else {
          if (isPlatform('hybrid')) {
            for (let i = 0; i < updatedSavedImages.length; i++) {
              const file = await Filesystem.getUri({
                path: updatedSavedImages[i].fileName,
                directory: Directory.Data,
              });
              updatedSavedImages[i].useableFilePath = Capacitor.convertFileSrc(file.uri);
            }
          }
          setStoredImages(updatedSavedImages);
        }
       
        const savedLikedParties = await store!.get(PARTIESLIKED_KEY) || [];
        const savedLikedDragExpos = await store!.get(DRAGEXPOSLIKED_KEY) || [];
        const savedLikedBarsAndClubs = await store!.get(BARSANDCLUBSLIKED_KEY) || [];
        const savedLikedPeople = await store!.get(PEOPLELIKED_KEY) || [];
        const savedLikedCruises = await store!.get(CRUISESLIKED_KEY) || [];
        const savedLikedCircFests = await store!.get(CIRCFESTSLIKED_KEY) || [];
        const savedViewedNotifications = await store!.get(NOTIFICATIONSVIEWED_KEY) || [];
        const savedDefCity = await store!.get(DEFCITY_KEY) || "";
        const savedViewedPromos = await store!.get(PROMOSVIEWED_KEY) || [];

        setLikedParties(savedLikedParties);
        setLikedDragExpos(savedLikedDragExpos);
        setLikedBarsAndClubs(savedLikedBarsAndClubs);
        setLikedPeople(savedLikedPeople);
        setLikedCruises(savedLikedCruises);
        setLikedCircFests(savedLikedCircFests);
        setViewedNotifications(savedViewedNotifications);
        setViewedNotificationsAreLoaded(true);
        setDefCity(savedDefCity);
        setDefCityIsLoaded(true);
        setViewedPromos(savedViewedPromos);
        setViewedPromosAreLoaded(true);

        setStoreIsLoaded(true);
      }

      initStorage();

    }, []);

    
    async function base64FromPath(path: string): Promise<string> {
        try {
          const response = await fetch(path);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          return await blobToBase64(blob);
        } catch (error) {
          console.error('Error in base64FromPath:', error);
          throw error;
        }
    }

    function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              resolve(reader.result);
            } else {
              reject(new Error('FileReader did not return a string'));
            }
          };
          reader.onerror = () => reject(new Error('FileReader error'));
          reader.readAsDataURL(blob);
        });
    }


    const saveImageToFileSystem = async (imageUrl: string, fileName: string, id: number, name: string, ext:string): Promise<Image> => {
        try {
          const base64data = await base64FromPath(imageUrl);
          
          // const savedFile = await Filesystem.writeFile({
          //   path: fileName,
          //   directory: Directory.Data,
          //   data: base64data
          // });

          // const usable = isPlatform('hybrid') ? Capacitor.convertFileSrc(savedFile.uri) : imageUrl;

          console.log(base64data, 'base 64');

          let useableFilePath: string;

          if (isPlatform('hybrid')) {
            // On mobile devices, save the image to the filesystem and get a usable path
            const savedFile = await Filesystem.writeFile({
              path: fileName,
              directory: Directory.Data,
              data: base64data,
            });
            useableFilePath = Capacitor.convertFileSrc(savedFile.uri);
          } else {
            // On the web, use the base64 data directly
            // useableFilePath = `data:image/${ext};base64,${base64data}`;
            useableFilePath = base64data;
            //above is an option but not sure if i need to save base64 data in Ionic Storage... extra storage space used may not be worth it...
            // useableFilePath = imageUrl;
          }
      
          return {
            fileName,
            // filePath: savedFile.uri,
            // filePath: "",
            //i dont think i actually need the above, just keeping it as emptty string becuase of the Image interface...
            // useableFilePath: Capacitor.convertFileSrc(savedFile.uri),
            useableFilePath,
            id,
            imageUrl,
            name
          };
      
        } catch (error) {
          console.error(`Failed to save image: ${name} (ID: ${id})`, error);
          throw error; // Re-throw to allow calling code to handle it
        }
      }
  
   

      const addArrayOfImagesToFileSystemThenAddToState = async (arrayOfImagesToStore: any) => {
        setIsDownloading(true);
        try {
          setDownloadProgress(0);
            const totalImages = arrayOfImagesToStore.length;
            let downloadedImages = 0;
    
            const arrayOfDetailsOfNewImagesSavedToFileSystem = await Promise.all(arrayOfImagesToStore.map(async (image: any) => {
              const fileExtension = image.file_type || 'jpeg'; // Use file_type or default to 'jpeg'
              const result = await saveImageToFileSystem(image.url, `${image.name}.${fileExtension}`, image.id, image.name, fileExtension);
                downloadedImages++;
                setDownloadProgress(Math.floor((downloadedImages / totalImages) * 100));
                return result;
            }));

            setStoredImages((prev: any) => [...prev, ...arrayOfDetailsOfNewImagesSavedToFileSystem]);

        } catch (error) {
            console.error('Error in addArrayOfImagesToFileSystemThenAddToState:', error);
            throw error;
        } finally {
            setIsDownloading(false);
        }
    };

   
    

    const getFileSize = async (filePath: string) => {
      // why is filePath used as a param when actually just name. and given just name, how can it be used below... also below only relevant for phones now since not writing anything to filesystem for web
      try {
        const fileInfo = await Filesystem.stat({
          path: filePath,
          directory: Directory.Data
        });
        return fileInfo.size;
      } catch (error) {
        console.error(`Error getting file size for ${filePath}:`, error);
        return 0;
      }
    };
  
    const logTotalImageStorageSize = async () => {
      try {
        let totalSize = 0;
  
        for (const image of storedImages) {
          const fileSize = await getFileSize(image.fileName);
          totalSize += fileSize;
        }
  
        console.log(`Total storage used by images: ${(totalSize / (1024 * 1024)).toFixed(2)} MB`);
      } catch (error) {
        console.error('Error calculating total image storage size:', error);
      }
    };
  
    const logIonicStorageUsage = async (store: Storage) => {
      try {
        let totalSize = 0;
    
        // Get all keys in the storage
        const keys = await store.keys();
    
        // Iterate through each key
        for (const key of keys) {
          // Get the value associated with the key
          const value = await store.get(key);
          // Convert the value to a JSON string
          const stringValue = JSON.stringify(value);
          // Calculate the byte size of the string
          totalSize += stringValue.length;
        }
    
        // Convert the total size from bytes to megabytes
        const totalSizeInMB = (totalSize / (1024 * 1024)).toFixed(2);
        console.log(`Total storage used in Ionic Storage: ${totalSizeInMB} MB`);
      } catch (error) {
        console.error('Error calculating total Ionic Storage usage:', error);
      }
    };


    const deleteFile = async (imgToDelete: any) => {
        try {
          await Filesystem.deleteFile({
            path: imgToDelete.fileName,
            directory: Directory.Data
          });
        } catch (error) {
          console.error(`Failed to delete image: ${imgToDelete.name} (ID: ${imgToDelete.id})`, error);
        }
      }



    const checkIonicStorageImagePointers = async (realtimeDatabaseImagesData: any[]) => {
  
        try {
          const imagesDataInIonicStorage = await store?.get(ALLIMAGES_KEY) || [];
      
          // Create maps for quick lookup
          const dbImageMap = new Map(realtimeDatabaseImagesData.map(img => [img.name, img]));
          const storageImageMap = new Map(imagesDataInIonicStorage.map((img: any) => [img.name, img]));
      
          // Identify images to delete and download
          const imagesToDelete = imagesDataInIonicStorage.filter((img: any) => !dbImageMap.has(img.name));
          const imagesToDownload = realtimeDatabaseImagesData.filter(img => !storageImageMap.has(img.name));

          console.log('check here yyy', imagesDataInIonicStorage, imagesToDownload, storageImageMap);
      
          // Delete images not in the database
          if (imagesToDelete.length > 0) {
            await Promise.all(imagesToDelete.map(deleteFile));
            setStoredImages(prevImages => prevImages.filter(img => dbImageMap.has(img.name)));
          }
      
          // Download new images
          if (imagesToDownload.length > 0) {
            // Download and store images
            await addArrayOfImagesToFileSystemThenAddToState(imagesToDownload);
          }

          setImagesAreLoaded(true);
          setFirstLoad(false);
        } catch (error) {
          console.error('Error in checkIonicStorageImagePointers:', error);          
        }
      }

      


    const checkExistingNotifsAndDetermineAction = async (listcities: any) => {
        
       
        const citiesDataInIonicStorage = await store?.get(CITIESNOTIFS_KEY) || null;
  

        if (!citiesDataInIonicStorage) {
         

            await Promise.all(listcities.map(async (city: any) => {
                // OneSignal.User.addTag(city, "true");
                setCitiesNotifs((prev: any)=>[...prev, city]);
            }));

            setCityNotifsAreLoaded(true);
         
        }
        
        else {
            setCitiesNotifs(citiesDataInIonicStorage);
            setCityNotifsAreLoaded(true);
        }
    
    }


    useEffect(() => {
      if (storedImages.length === 0) return;
      // console.log(storedImages, 'what stored images');
      const setStoredAsync = async () => {
          await store!.set(ALLIMAGES_KEY, storedImages);
          // await logTotalImageStorageSize();
          //above onyl relevant for phones not web.
          if (store) logIonicStorageUsage(store);          
      }
      if (storeIsLoaded) {
          setStoredAsync();
      }
    }, [storedImages, storeIsLoaded]);

    useEffect(() => {
        if (storeIsLoaded) {
            store?.set(PARTIESLIKED_KEY, likedParties);
        }
    }, [likedParties]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(DRAGEXPOSLIKED_KEY, likedDragExpos);
        }
    }, [likedDragExpos]);

    useEffect(() => {
        if (storeIsLoaded) {
            // console.log('this ran bc');
        store?.set(BARSANDCLUBSLIKED_KEY, likedBarsAndClubs);
        }
    }, [likedBarsAndClubs]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(PEOPLELIKED_KEY, likedPeople);
        }
    }, [likedPeople]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(CRUISESLIKED_KEY, likedCruises);
        }
    }, [likedCruises]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(CIRCFESTSLIKED_KEY, likedCircFests);
        }
    }, [likedCircFests]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(NOTIFICATIONSVIEWED_KEY, viewedNotifications);
        }
    }, [viewedNotifications]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(DEFCITY_KEY, defCity);
        }
    }, [defCity]);

    useEffect(() => {
        if (storeIsLoaded && cityNotifsAreLoaded) {
        store?.set(CITIESNOTIFS_KEY, citiesNotifs);
        }
    }, [citiesNotifs]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(PROMOSVIEWED_KEY, viewedPromos);
        }
    }, [viewedPromos]);

  
    return {
        storeIsLoaded,
        firstLoad,
        imagesAreLoaded,
        checkIonicStorageImagePointers,
        storedImages,
        checkExistingNotifsAndDetermineAction,
        viewedNotifications,
        setViewedNotifications,
        viewedNotificationsAreLoaded,
        defCity,
        setDefCity,
        defCityIsLoaded,
        citiesNotifs,
        setCitiesNotifs,
        viewedPromos,
        setViewedPromos,
        viewedPromosAreLoaded,
        likedParties,
        setLikedParties,
        likedDragExpos,
        setLikedDragExpos,
        likedBarsAndClubs,
        setLikedBarsAndClubs,
        likedPeople,
        setLikedPeople,
        likedCruises,
        setLikedCruises,
        likedCircFests,
        setLikedCircFests,
        showToast,
        setShowToast,
        isDownloading,
        downloadProgress,
    }
}