// later for each of parties, drag expos, and cruises and tours.. you are going to need to have logic where it's one or two...

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonMenuButton
} from '@ionic/react';

import {
    globeOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    tvOutline,
    logoYoutube,
    logoSoundcloud,
    alertCircle,
    logoTiktok,
    alertCircleOutline,
    chevronBack
} from 'ionicons/icons';


import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';


const PersonWindow: React.FC<{
    isWeb: any;
    gridNo: any;
    people: any[];
    parties: any[];
    festivalsCirc: any;
    festivalsPr: any;
    dragExpos: any;
    dragTours: any[];
    cruises: any;
    likedPeople: any;
    setLikedPeople: any;
    handleIncrement: any;
    handleDecrement: any;
    citySelected: any;
}> = (props) => {

    const selectedPersonID = useParams<{ id: string }>().id;
    const selectedPerson = props.people.find((person: any) => person.id === selectedPersonID);
    // console.log(selectedPerson, 'selected person');

    const [partiesPP, setPartiesPP] = useState<any[]>([]);
    const [partiesPPC, setPartiesPPC] = useState<any[]>([]);
    const [partiesPPP, setPartiesPPP] = useState<any[]>([]);
    const [dragExposPP, setDragExposPP] = useState<any[]>([]);
    const [dragToursPP, setDragToursPP] = useState<any[]>([]);
    const [cruisesPP, setCruisesPP] = useState<any[]>([]);

    const handleLikePersonW = (hashpp: any) => {
        // event.preventDefault();
        props.setLikedPeople((prev: any)=>[...prev, hashpp]);
        props.handleIncrement("people", hashpp);
      }
    
      const handleUnlikePersonW = (hashpp: any) => {
        // event.preventDefault();
        props.setLikedPeople(props.likedPeople.filter((personlikedhash:any) => personlikedhash !== hashpp));
        props.handleDecrement("people", hashpp);
      }

    useEffect(() => {
        const dataPartiesPP:any = [];
        for (let i = 0; i < props.parties.length; i++) {
            if (
                props.parties[i].entertainers !== ""
                &&
                (props.parties[i].entertainers.some((entertainer:any) => entertainer === selectedPerson?.name))
                &&
                (dataPartiesPP.indexOf(props.parties[i] === -1))
            ) {
                dataPartiesPP.push(props.parties[i])
            }
        }
        setPartiesPP(dataPartiesPP);

        // const dataPartiesPPC:any = [];
        // for (let i = 0; i < props.festivalsCirc.length; i++) {
        //     if (
        //         props.festivalsCirc[i].entertainers.length > 0
        //         &&
        //         (props.festivalsCirc[i].entertainers.filter((entertainer:any) => entertainer === selectedPerson?.name).length > 0)
               
        //     ) {
        //         dataPartiesPPC.push(props.festivalsCirc[i])
        //     }
        // }
        // setPartiesPPC(dataPartiesPPC);

        // const dataPartiesPPP:any = [];
        // for (let i = 0; i < props.festivalsPr.length; i++) {
        //     if (
        //         props.festivalsPr[i].entertainers.length > 0
        //         &&
        //         (props.festivalsPr[i].entertainers.filter((entertainer:any) => entertainer === selectedPerson?.name).length > 0)
                
        //     ) {
        //         dataPartiesPPP.push(props.festivalsPr[i])
        //     }
        // }
        // setPartiesPPP(dataPartiesPPP);
        
        //not including festivals for the moment hence above commented out.
        
        const dataDragExposPP:any = [];
        for (let i = 0; i < props.dragExpos.length; i++) {
            if (
                props.dragExpos[i].type === "Expo"
                &&
                props.dragExpos[i].entertainers !== ""
                &&
                (props.dragExpos[i].entertainers.some((entertainer:any) => entertainer === selectedPerson?.name))
                &&
                (dataDragExposPP.indexOf(props.dragExpos[i] === -1))
            ) {
                dataDragExposPP.push(props.dragExpos[i])
            }
        }
        setDragExposPP(dataDragExposPP);

        const dataDragToursPP:any = [];
        for (let i = 0; i < props.dragTours.length; i++) {
            if (
                props.dragTours[i].entertainers !== ""
                &&
                (props.dragTours[i].entertainers.some((entertainer:any) => entertainer === selectedPerson?.name))
                &&
                (dataDragToursPP.indexOf(props.dragTours[i] === -1))
            ) {
                dataDragToursPP.push(props.dragTours[i])
            }
        }
        setDragToursPP(dataDragToursPP);

        const dataCruisesPP:any = [];
        for (let i = 0; i < props.cruises.length; i++) {
            if (
                props.cruises[i].entertainers !== ""
                &&
                (props.cruises[i].entertainers.some((entertainer:any) => entertainer === selectedPerson?.name))
                &&
                (dataCruisesPP.indexOf(props.cruises[i] === -1))
            ) {
                dataCruisesPP.push(props.cruises[i])
            }
        }
        setCruisesPP(dataCruisesPP);


      }, [props.parties, props.festivalsCirc, props.dragExpos, props.dragTours, props.cruises, selectedPerson]);

    //   still need to add drag tours


    return(
        <IonPage>
            {
                selectedPerson ? (
                <IonContent>
                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="ionbackbuttoncontoth">
                            <IonButtons
                        
                            >
                                <IonBackButton defaultHref="/" text="Back" icon={chevronBack} className="backsize">
                                </IonBackButton>
                            </IonButtons>
                        </div>
                        <div className="mainmenucont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonMenuButton className="windowmenutog" />
                        </IonButtons>
                        </div>
                        {              
                        
                            !props.isWeb
                            &&   
                        props.likedPeople.filter((likedpersonhash: any) => likedpersonhash === selectedPerson.hash).length === 0
                        &&
                        <div
                        className="windowheartcont"
                        // onClick={()=>props.setLikedPeople((prev: any)=>[...prev, selectedPerson.id])}
                        onClick={() => handleLikePersonW(selectedPerson.hash)}
                        >
                            <IonIcon icon={heartOutline} color="danger" className="windowheart" />
                        </div>
                        }
                        {
                            
                                !props.isWeb
                                &&
                        props.likedPeople.filter((likedpersonhash: any) => likedpersonhash === selectedPerson.hash).length > 0
                        &&
                        <div
                        className="windowheartcont"
                        // onClick={()=>props.setLikedPeople(props.likedPeople.filter((likedpersonid: any) => likedpersonid !== selectedPerson.id))}
                        onClick={() => handleUnlikePersonW(selectedPerson.hash)}
                        >
                            <IonIcon icon={heartSharp} color="danger" className="windowheart" />
                            {/* <div className="windowheartlit"></div> */}
                        </div>
                        }

                        <div className="windowtopcont">
                            {
                                selectedPerson.image_src
                                &&
                                <img className="windowimgbox" src={selectedPerson.image_src} alt=""
                                onError={(e: any) => e.target.src = selectedPerson.image_url}
                                ></img>
                            }
                            {
                                !selectedPerson.image_src
                                &&
                                <img className="windowimgbox" src={selectedPerson.image_url} alt=""
                                ></img>
                            }
                            <div className="windowimggrad">
                                <div className="windowtitlebox">
                                    <div className="windowtitlefull">
                                        {selectedPerson.name}
                                    </div>
                                    {/* <div className="titleboxrightPP">
                                        <div className="titlerighttypegrid">
                                            {
                                            selectedPerson.type.includes("Drag queen")
                                            &&
                                            <div className="titlerighttypebubble typedrag">Drag</div>
                                            }
                                            {
                                            !(selectedPerson.type.includes("Drag queen"))
                                            &&
                                            <div className="titlerighttypebubble typedrag typeop">Drag</div>
                                            }
                                            {
                                            selectedPerson.type.includes("DJ")
                                            &&
                                            <div className="titlerighttypebubble typedj">DJ</div>
                                            }
                                            {
                                            !(selectedPerson.type.includes("DJ"))
                                            &&
                                            <div className="titlerighttypebubble typedj typeop">DJ</div>
                                            }
                                            {
                                            selectedPerson.type.includes("Singer")
                                            &&
                                            <div className="titlerighttypebubble typesing">Singer</div>
                                            }
                                            {
                                            !(selectedPerson.type.includes("Singer"))
                                            &&
                                            <div className="titlerighttypebubble typesing typeop">Singer</div>
                                            }
                                            {
                                            selectedPerson.type.includes("Other")
                                            &&
                                            <div className="titlerighttypebubble typeoth">Other</div>
                                            }
                                            {
                                            !(selectedPerson.type.includes("Other"))
                                            &&
                                            <div className="titlerighttypebubble typeoth typeop">Other</div>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        
                        <div className="windowmainsetwidth">

                           

                            {
                            (selectedPerson.description !== "" || selectedPerson.extra_info !== "")
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedPerson.description}
                                    </div>
                                    {/* {
                                    selectedPerson.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedPerson.extra_info}</div>
                                    </div>
                                    } */}
                                    {
                                (selectedPerson.extra_info !== "")
                                &&
                                <div className="festbtncont">
                                        

                                        {
                                        
                                        selectedPerson.extra_info !== ""
                                        &&
                                        <div className="festtag">
                                                <div className="triancont">
                                                {/* <img className="trianwin" src={triimg} alt="" /> */}
                                                <IonIcon icon={alertCircleOutline} className="extalert" />
                                                </div>
                                                {selectedPerson.extra_info}
                                        </div>
                                        }
                                        
                                        
                                </div>

                            }
                            </div>
                            }

                            {/* could put tv shows at bottom of about section */}


                        {
                            selectedPerson.tv_shows.length > 0
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Credits
                                    </div>  
                                    {
                                        selectedPerson.tv_shows.map((ele: any, index: number) => {
                                            return <div key={index} className="tvshowlistcont">
                                                        <div className="tvshowlistitem">
                                                            <IonIcon icon={tvOutline} size="small" color="primary" />
                                                        </div>
                                                        <div className="tvshowlistitem">{ele}</div>
                                                </div>
                                        })
                                    }
                            </div>
                            }

                        
                            <div className="windowmainboxstd">

                                {
                                (selectedPerson.instagram !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(`https://www.instagram.com/${selectedPerson.instagram}`, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Instagram</div>
                                        <div className="wmtxtb">@{selectedPerson.instagram}</div>
                                    </div>
                                        
                                    <div className="wmiconinsta"
                                    
                                    >
                                            <IonIcon icon={logoInstagram} className="insticn" />
                                    </div>
                                </div>
                                }
                                {
                                (selectedPerson.tiktok !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(`https://www.tiktok.com/@${selectedPerson.tiktok}`, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">TikTok</div>
                                        <div className="wmtxtb">@{selectedPerson.tiktok}</div>
                                    </div>
                                    <div className="wmicontt"
                                    
                                    >
                                        <IonIcon icon={logoTiktok} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                                {
                                (selectedPerson.facebook !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(`https://www.facebook.com/${selectedPerson.facebook}`, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Facebook</div>
                                        <div className="wmtxtb">{selectedPerson.facebook}</div>
                                    </div>
                                        
                                    <div className="wmiconfb"
                                    
                                    >
                                            <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                    </div>
                                </div>
                                }
                                {/* {
                                (selectedPerson?.facebook !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedPerson.facebook, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Facebook</div>
                                        <div className="wmtxtb">@{selectedPerson.facebook.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconfb"
                                    
                                    >
                                        <IonIcon icon={logoFacebook} color="white" className="insticn" />
                                    </div>
                                </div>
                                } */}

                                {
                                (selectedPerson.soundcloud !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson.soundcloud}`, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">SoundCloud</div>
                                        <div className="wmtxtb">@{selectedPerson.soundcloud}</div>
                                    </div>
                                    <div className="wmiconsc2"
                                    
                                    >
                                        <IonIcon icon={logoSoundcloud} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                                {
                                (selectedPerson.youtube !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(`https://www.youtube.com/${selectedPerson.youtube}`, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Youtube</div>
                                        <div className="wmtxtb">@{selectedPerson.youtube}</div>
                                    </div>
                                    <div className="wmiconyou"
                                    
                                    >
                                        <IonIcon icon={logoYoutube} color="white" className="icoth2" />
                                    </div>
                                </div>
                                }
                                {
                                (selectedPerson.website !== "")
                                &&
                                <div className="wmcont"
                                onClick={()=>window.open(selectedPerson.website, '_system', 'location=yes')}
                                >
                                    <div className="wmflex">
                                        <div className="wmtxt">Website</div>
                                        <div className="wmtxtb">{selectedPerson.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}</div>
                                    </div>
                                    <div className="wmiconsc"
                                    // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson.youtube}`, '_system', 'location=yes')}
                                    
                                    >
                                        <IonIcon icon={globeOutline} color="white" className="icoth" />
                                    </div>
                                </div>
                                }
                            </div>
                            
                            
                            

                            {/* {
                            props.parties.filter(party => party.entertainers !== "").filter(party => party.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            props.dragExpos.filter((dragexpo: any) => dragexpo.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            props.cruises.filter((cruise: any) => cruise.entertainers !== "").filter((cruise: any) => cruise.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            <div className="partywindowheading">
                                Upcoming appearances
                            </div>
                            } */}

                            {
                            partiesPP.length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Parties{` (${props.citySelected.city})`}
                            </div>
                            }
                            {/* note how this heading is within the original main section hence slightly different code than below */}
                            </div>  
                            {/* since ive said upcoming maybe remove those that have started? or not..but just dont bother with live thing. */}

                            {
                                partiesPP.length >= 1
                                &&
                                (
                            (partiesPP.length === 1 && props.gridNo === 1)
                                    ||
                                    (partiesPP.length <= 2 && props.gridNo === 2)
                                    ||
                                    (partiesPP.length <= 3 && props.gridNo === 3)
                                    ||
                                    (partiesPP.length <= 4 && props.gridNo === 4)
                                    ||
                                    (partiesPP.length <= 5 && props.gridNo === 5)
                                )
                            &&
                            <div className="gridviewgridBC">
                                        {
                                        partiesPP.map((party, index) => {
                                            return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                                        <div className="gridviewcardBC">
                                                            {
                                                                party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_src} alt="" 
                                                                onError={(e: any) => e.target.src = party.image_url}

                                                                />

                                                            }
                                                             {
                                                                !party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_url} alt="" 
                                                                />

                                                            }       
                                                                    <div className="gridviewcardimagecover">

                                                                    {/* {
                                                                    (party.tickets_available && party.price > 0 && party.price !== 999)
                                                                    &&
                                                                    <div className="gridviewpricebubblealt"> 
                                                                        <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price}
                                                                    </div>
                                                                    }
                                                                    {
                                                                    party.tickets_available && party.price === 0
                                                                    &&
                                                                    <div className="gridviewpricebubblefree"> 
                                                                        Free
                                                                    </div>
                                                                    }
                                                                    {
                                                                    !party.tickets_available
                                                                    &&
                                                                    <div className="gridviewpricebubblesoldout"> 
                                                                        Sold out
                                                                    </div>
                                                                    } */}

                                                                        {/* <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontainer">
                                                                                                {
                                                                                            party.name_short === ""
                                                                                            &&
                                                                                            <div className="gridviewtitle">
                                                                                            {party.name}
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            party.name_short !== ""
                                                                                            &&
                                                                                            <div className="gridviewtitle">{party.name_short}</div>
                                                                                        }
                                                                            </div>
                                                                            <div className="gridviewtimecontainer">
                                                                                <div className="gridviewtimebubble">
                                                                                   
                                                                                    <div className="gridviewtimebubbletop">{party.start_date}{" "}</div>
                                                                                    <div className="gridviewtimebubblemon">{party.start_month.slice(0,3)}</div>
                                                                                </div> 
                                                                            </div>
                                                                        </div> */}
                                                                        {/* {
                                                                        !party.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        } */}
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{party.start_date}</div>
                                                                            
                                                                            <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                party.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name}</div>
                                                                            }
                                                                            {
                                                                                party.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name_short}</div>
                                                                            }
                                                                            
                                                                        
                                                                        </div>

                                                                    </div>
                                                                    </div>
                                                        </Link>           
                                        })}
                            </div>
                            }
                            {
                            partiesPP.length >= 1
                            &&
                            partiesPP.length > props.gridNo
                            &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                    <Swiper
                                    slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                    loop={false}
                                    modules={[IonicSlides]}
                                    // className="swiperaddspace"
                                    slidesOffsetBefore={10}
                                    slidesOffsetAfter={10}
                                    spaceBetween={2}
                                    >
                                        {
                                        partiesPP.map((party, index) => {
                                            return <SwiperSlide key={index}>
                                                        <div className="swipercard">
                                                                        <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                                                                    {
                                                                        party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_src} alt=""
                                                                        onError={(e: any) => e.target.src = party.image_url}

                                                                        />
                                                                    }
                                                                     {
                                                                        !party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_url} alt=""
                                                                        />
                                                                    }
                                                                    
                                                                    <div className="gridviewcardimagecover">

                                                                    {/* {
                                                                    (party.tickets_available && party.price > 0 && party.price !== 999)
                                                                    &&
                                                                    <div className="gridviewpricebubblealt"> 
                                                                        <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price}
                                                                    </div>
                                                                    }
                                                                    {
                                                                    party.tickets_available && party.price === 0
                                                                    &&
                                                                    <div className="gridviewpricebubblefree"> 
                                                                        Free
                                                                    </div>
                                                                    }
                                                                    {
                                                                    !party.tickets_available
                                                                    &&
                                                                    <div className="gridviewpricebubblesoldout"> 
                                                                        Sold out
                                                                    </div>
                                                                    } */}

                                                                        {/* <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontainer">
                                                                                            {
                                                                                        party.name_short === ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">
                                                                                        {party.name}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        party.name_short !== ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">{party.name_short}</div>
                                                                                    }
                                                                            </div>
                                                                            <div className="gridviewtimecontainer">
                                                                                <div className="gridviewtimebubble">
                                                                                    
                                                                                    <div className="gridviewtimebubbletop">{party.start_date}{" "}</div>
                                                                                    <div className="gridviewtimebubblemon">{party.start_month.slice(0,3)}</div>
                                                                                </div> 
                                                                            </div>
                                                                        </div> */}
                                                                            {/* {
                                                                            !party.tickets_available
                                                                            &&
                                                                            <div className="gridviewextrasoldoutbox">
                                                                                <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                            </div>
                                                                            } */}
                                                                            <div className="newgridcal">
                                                                                <div className="topdate">{party.start_date}</div>
                                                                                
                                                                                <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                            </div>

                                                                            <div className="gridviewbottomcontainer19">
                                                                                
                                                                                {
                                                                                    party.name_short === ""
                                                                                    &&
                                                                                    <div className="gridviewtitlefull">{party.name}</div>
                                                                                }
                                                                                {
                                                                                    party.name_short !== ""
                                                                                    &&
                                                                                    <div className="gridviewtitlefull">{party.name_short}</div>
                                                                                }
                                                                                
                                                                            
                                                                            </div>
                                                                    </div>
                                                                </Link>  
                                                            </div>
                                        </SwiperSlide>
                                        })}
                                    </Swiper>
                            </div>
                            }

                            
                            {/* {
                            partiesPPP.length > 0
                            &&
                            <div className="windowmainsetwidth">
                                
                                    <div className="windowmainheadingstd">
                                    Pride Festivals
                                    </div>
                                
                             </div>
                            }
                            {
                            partiesPPP.length >= 1
                            &&
                            (
                        (partiesPPP.length === 1 && props.gridNo === 1)
                                ||
                                (partiesPPP.length <= 2 && props.gridNo === 2)
                                ||
                                (partiesPPP.length <= 3 && props.gridNo === 3)
                                ||
                                (partiesPPP.length <= 4 && props.gridNo === 4)
                                ||
                                (partiesPPP.length <= 5 && props.gridNo === 5)
                            )
                        &&
                            <div className="gridviewgridBC">
                                        {
                                        partiesPPP.map((party, index) => {
                                            return <Link key={index} to={`/pride/${party.id}`} style={{ display: 'contents' }}>
                                                        <div className="gridviewcardBC">
                                                            {
                                                                party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_src} alt="" 
                                                                onError={(e: any) => e.target.src = party.image_url}

                                                                />

                                                            }
                                                             {
                                                                !party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_url} alt="" 
                                                                />

                                                            }       
                                                                    <div className="gridviewcardimagecover">

                                                                    

                                                                            {
                                                                            !party.tickets_available
                                                                            &&
                                                                            <div className="gridviewextrasoldoutbox">
                                                                                <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                            </div>
                                                                            }
                                                                            <div className="newgridcal">
                                                                                <div className="topdate">{party.start_date}</div>
                                                                                
                                                                                <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                            </div>

                                                                            <div className="gridviewbottomcontainer">
                                                                                <div className="gridviewtitlecontainer">
                                                                                {
                                                                                    party.name_short === ""
                                                                                    &&
                                                                                    <div className="gridviewtitlefull">{party.name}</div>
                                                                                }
                                                                                {
                                                                                    party.name_short !== ""
                                                                                    &&
                                                                                    <div className="gridviewtitlefull">{party.name_short}</div>
                                                                                }
                                                                                </div>
                                                                            
                                                                            </div>
                                                                    </div>
                                                                    </div>
                                                        </Link>           
                                        })}
                            </div>
                            }
                            {
                           partiesPPP.length >= 1
                           &&
                           partiesPPP.length > props.gridNo
                           &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                    <Swiper
                                    slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                    loop={false}
                                    modules={[IonicSlides]}
                                    className="swiperaddspace"
                                    slidesOffsetBefore={10}
                                    slidesOffsetAfter={10}
                                    spaceBetween={2}
                                    >
                                        {
                                        partiesPPP.map((party, index) => {
                                            return <SwiperSlide key={index}>
                                                        <div className="swipercard">
                                                                        <Link to={`/pride/${party.id}`} style={{ display: 'contents' }}>
                                                                    {
                                                                        party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_src} alt=""
                                                                        onError={(e: any) => e.target.src = party.image_url}

                                                                        />
                                                                    }
                                                                     {
                                                                        !party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_url} alt=""
                                                                        />
                                                                    }
                                                                    
                                                                    <div className="gridviewcardimagecover">

                                                                   


                                                                        
                                                                 

                                                                        {
                                                                        !party.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        }
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{party.start_date}</div>
                                                                            
                                                                            <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontainer">
                                                                            {
                                                                                party.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name}</div>
                                                                            }
                                                                            {
                                                                                party.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name_short}</div>
                                                                            }
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                </Link>  
                                                            </div>
                                        </SwiperSlide>
                                        })}
                                    </Swiper>
                            </div>
                            } */}







{/* commented out below as not having performers linked to circuit festivals for the moment. */}

                            {/* {
                            partiesPPC.length > 0
                            &&
                            <div className="windowmainsetwidth">
                                
                                    <div className="windowmainheadingstd">
                                    Circuit Festivals
                                    </div>
                                
                             </div>
                            }
                            {
                            partiesPPC.length >= 1
                            &&
                            (
                        (partiesPPC.length === 1 && props.gridNo === 1)
                                ||
                                (partiesPPC.length <= 2 && props.gridNo === 2)
                                ||
                                (partiesPPC.length <= 3 && props.gridNo === 3)
                                ||
                                (partiesPPC.length <= 4 && props.gridNo === 4)
                                ||
                                (partiesPPC.length <= 5 && props.gridNo === 5)
                            )
                        &&
                            <div className="gridviewgridBC">
                                        {
                                        partiesPPC.map((party, index) => {
                                            return <Link key={index} to={`/circuit/${party.id}`} style={{ display: 'contents' }}>
                                                        <div className="gridviewcardBC">
                                                            {
                                                                party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_src} alt="" 
                                                                onError={(e: any) => e.target.src = party.image_url}

                                                                />

                                                            }
                                                             {
                                                                !party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_url} alt="" 
                                                                />

                                                            }       
                                                                    <div className="gridviewcardimagecover">

                                                                  
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{party.start_date}</div>
                                                                            
                                                                            <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                party.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name}</div>
                                                                            }
                                                                            {
                                                                                party.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name_short}</div>
                                                                            }
                                                                           
                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </Link>           
                                        })}
                            </div>
                            }
                            {
                           partiesPPC.length >= 1
                           &&
                           partiesPPC.length > props.gridNo
                           &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                    <Swiper
                                    slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                    loop={false}
                                    modules={[IonicSlides]}
                                    slidesOffsetBefore={10}
                                    slidesOffsetAfter={10}
                                    spaceBetween={2}
                                    >
                                        {
                                        partiesPPC.map((party, index) => {
                                            return <SwiperSlide key={index}>
                                                        <div className="swipercard">
                                                                        <Link to={`/circuit/${party.id}`} style={{ display: 'contents' }}>
                                                                    {
                                                                        party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_src} alt=""
                                                                        onError={(e: any) => e.target.src = party.image_url}

                                                                        />
                                                                    }
                                                                     {
                                                                        !party.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={party.image_url} alt=""
                                                                        />
                                                                    }
                                                                    
                                                                    <div className="gridviewcardimagecover">

                                                                   
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{party.start_date}</div>
                                                                            
                                                                            <div className="botdate">{party.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                party.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name}</div>
                                                                            }
                                                                            {
                                                                                party.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{party.name_short}</div>
                                                                            }
                                                                         
                                                                        
                                                                        </div>
                                                                    </div>
                                                                </Link>  
                                                            </div>
                                        </SwiperSlide>
                                        })}
                                    </Swiper>
                            </div>
                            } */}

                           
                            {
                            dragToursPP.length > 0
                            &&
                            <div className="windowmainsetwidth">
                                
                                    <div className="windowmainheadingstd">
                                    Drag Tours
                                    </div>
                                
                             </div>
                            }

                            {
                            dragToursPP.length >= 1
                            &&
                            (
                        (dragToursPP.length === 1 && props.gridNo === 1)
                                ||
                                (dragToursPP.length <= 2 && props.gridNo === 2)
                                ||
                                (dragToursPP.length <= 3 && props.gridNo === 3)
                                ||
                                (dragToursPP.length <= 4 && props.gridNo === 4)
                                ||
                                (dragToursPP.length <= 5 && props.gridNo === 5)
                            )
                        &&
                            <div className="gridviewgridBC">
                                {
                                dragToursPP.map((dragexpo: any, index: any) => {
                                        return <Link key={index} to={`/drag/${dragexpo.id}`} style={{ display: 'contents' }}>
                                                        <div className="gridviewcardBC">
                                                            {
                                                                dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                onError={(e: any) => e.target.src = dragexpo.image_url}
                                                                />
                                                            }
                                                             {
                                                                !dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_url} alt=""
                                                                />
                                                            }       
                                                                    <div className="gridviewcardimagecover">
                                                                        
                                                                      

                                                                        {/* <div className="gridviewdatebubble">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                    </div> */}

                                                                        {/* <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontaineroth">
                                                                                    {
                                                                                        dragexpo.name_short === ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">
                                                                                        {dragexpo.name}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        dragexpo.name_short !== ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">{dragexpo.name_short}</div>
                                                                                    }
                                                                            </div>
                                                                            <div className="gridviewtimecontaineroth">
                                                                                <div className="gridviewtimebubblelive">
                                                                                    <div className="livefrom">from</div>
                                                                                    <div className="colorpi">{dragexpo.currency_symbol}{dragexpo.starting_price}</div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div> */}
                                                                        {/* {
                                                                        !dragexpo.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        } */}
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                dragexpo.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name}</div>
                                                                            }
                                                                            {
                                                                                dragexpo.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name_short}</div>
                                                                            }
                                                                          
                                                                        
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </Link>
                                        })
                                }
                             </div>
                            }

                            {
                            dragToursPP.length >= 1
                            &&
                            dragToursPP.length > props.gridNo
                            &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                <Swiper
                                slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                loop={false}
                                modules={[IonicSlides]}
                                // className="swiperaddspace"
                                // above was id before. add to parties swiper too?
                                // spaceBetween={6}
                                slidesOffsetBefore={10}
                                slidesOffsetAfter={10}
                                spaceBetween={2}
                                >
                                {
                                dragToursPP.map((dragexpo: any, index: any) => {
                                        return <SwiperSlide key={index}>
                                                            <div className="swipercard">
                                                                <Link to={`/drag/${dragexpo.id}`} style={{ display: 'contents' }}>
                                                                    {
                                                                        dragexpo.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                        onError={(e: any) => e.target.src = dragexpo.image_url}

                                                                        />
                                                                    }
                                                                    {
                                                                        !dragexpo.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={dragexpo.image_url} alt=""

                                                                        />
                                                                    }
                                                                    
                                                                    <div className="gridviewcardimagecover">
                                                                        {/* {
                                                                        !dragexpo.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        } */}
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                dragexpo.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name}</div>
                                                                            }
                                                                            {
                                                                                dragexpo.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name_short}</div>
                                                                            }
                                                                     
                                                                        
                                                                        </div>
                                                                      
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                    </SwiperSlide>
                                        })
                                }
                                </Swiper>
                            </div>
                            }

{
                            dragExposPP.length > 0
                            &&
                            <div className="windowmainsetwidth">
                                
                                    <div className="windowmainheadingstd">
                                    Drag Conventions
                                    </div>
                                
                             </div>
                            }

                            {
                            dragExposPP.length >= 1
                            &&
                            (
                        (dragExposPP.length === 1 && props.gridNo === 1)
                                ||
                                (dragExposPP.length <= 2 && props.gridNo === 2)
                                ||
                                (dragExposPP.length <= 3 && props.gridNo === 3)
                                ||
                                (dragExposPP.length <= 4 && props.gridNo === 4)
                                ||
                                (dragExposPP.length <= 5 && props.gridNo === 5)
                            )
                        &&
                            <div className="gridviewgridBC">
                                {
                                dragExposPP.map((dragexpo: any, index: any) => {
                                        return <Link key={index} to={`/drag/${dragexpo.id}`} style={{ display: 'contents' }}>
                                                        <div className="gridviewcardBC">
                                                            {
                                                                dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                onError={(e: any) => e.target.src = dragexpo.image_url}
                                                                />
                                                            }
                                                             {
                                                                !dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_url} alt=""
                                                                />
                                                            }       
                                                                    <div className="gridviewcardimagecover">
                                                                        
                                                                      

                                                                        {/* <div className="gridviewdatebubble">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                    </div> */}

                                                                        {/* <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontaineroth">
                                                                                    {
                                                                                        dragexpo.name_short === ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">
                                                                                        {dragexpo.name}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        dragexpo.name_short !== ""
                                                                                        &&
                                                                                        <div className="gridviewtitle">{dragexpo.name_short}</div>
                                                                                    }
                                                                            </div>
                                                                            <div className="gridviewtimecontaineroth">
                                                                                <div className="gridviewtimebubblelive">
                                                                                    <div className="livefrom">from</div>
                                                                                    <div className="colorpi">{dragexpo.currency_symbol}{dragexpo.starting_price}</div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div> */}
                                                                        {/* {
                                                                        !dragexpo.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        } */}
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                dragexpo.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name}</div>
                                                                            }
                                                                            {
                                                                                dragexpo.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name_short}</div>
                                                                            }
                                                                          
                                                                        
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </Link>
                                        })
                                }
                             </div>
                            }

                            {
                            dragExposPP.length >= 1
                            &&
                            dragExposPP.length > props.gridNo
                            &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                <Swiper
                                slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                loop={false}
                                modules={[IonicSlides]}
                                // className="swiperaddspace"
                                // above was id before. add to parties swiper too?
                                // spaceBetween={6}
                                slidesOffsetBefore={10}
                                slidesOffsetAfter={10}
                                spaceBetween={2}
                                >
                                {
                                dragExposPP.map((dragexpo: any, index: any) => {
                                        return <SwiperSlide key={index}>
                                                            <div className="swipercard">
                                                                <Link to={`/drag/${dragexpo.id}`} style={{ display: 'contents' }}>
                                                                    {
                                                                        dragexpo.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                        onError={(e: any) => e.target.src = dragexpo.image_url}

                                                                        />
                                                                    }
                                                                    {
                                                                        !dragexpo.image_src
                                                                        &&
                                                                        <img className="gridviewcardimage" src={dragexpo.image_url} alt=""

                                                                        />
                                                                    }
                                                                    
                                                                    <div className="gridviewcardimagecover">
                                                                        {/* {
                                                                        !dragexpo.tickets_available
                                                                        &&
                                                                        <div className="gridviewextrasoldoutbox">
                                                                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                                                                        </div>
                                                                        } */}
                                                                        <div className="newgridcal">
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                        </div>

                                                                        <div className="gridviewbottomcontainer19">
                                                                            
                                                                            {
                                                                                dragexpo.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name}</div>
                                                                            }
                                                                            {
                                                                                dragexpo.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull">{dragexpo.name_short}</div>
                                                                            }
                                                                     
                                                                        
                                                                        </div>
                                                                      
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                    </SwiperSlide>
                                        })
                                }
                                </Swiper>
                            </div>
                            }

                            {
                                cruisesPP.length > 0
                                &&
                                <div className="windowmainsetwidth">
                                    <div className="windowmainheadingstd">
                                    Cruises
                                    </div>
                                </div>
                            }

                            {
                           cruisesPP.length >= 1
                           &&
                           (
                       (cruisesPP.length === 1 && props.gridNo === 1)
                               ||
                               (cruisesPP.length <= 2 && props.gridNo === 2)
                               ||
                               (cruisesPP.length <= 3 && props.gridNo === 3)
                               ||
                               (cruisesPP.length <= 4 && props.gridNo === 4)
                               ||
                               (cruisesPP.length <= 5 && props.gridNo === 5)
                           )
                       &&
                            <div className="gridviewgridBC">
                            {
                            cruisesPP.map((cruise: any, index: number) => {
                                    return <Link key={index} to={`/cruises/${cruise.id}`} style={{ display: 'contents' }}>
                                                <div className="gridviewcardBC">
                                                    {
                                                        cruise.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={cruise.image_src} alt=""
                                                        onError={(e: any) => e.target.src = cruise.image_url}
                                                        />

                                                    }
                                                    {
                                                        !cruise.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={cruise.image_url} alt=""
                                                        />

                                                    }
                                                    <div className="gridviewcardimagecover">
                                                        {/* <div className="gridviewcitybbl">
                                                            <span className="livefromcr">departs</span>{cruise.start_date}{" "}{cruise.start_month.slice(0,3)}
                                                        </div> */}
                                                        <div className="newgridcaltt">
                                                            <div className="livefromnewoth">from</div>
                                                            
                                                            <div className="colorw">{cruise.start_date}{" "}{cruise.start_month.slice(0,3)}</div> 

                                                        </div>

                                                        <div className="gridviewbottomcontainer19">               
                                                            
                                                                                    {
                                                                                        cruise.name_short === ""
                                                                                        &&
                                                                                        <div className="gridviewtitlefull">
                                                                                        {cruise.name}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        cruise.name_short !== ""
                                                                                        &&
                                                                                        <div className="gridviewtitlefull">{cruise.name_short}</div>
                                                                                    }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                    </Link>

                                })
                            }
                            </div>
                            }
                            
                            {
                            cruisesPP.length >= 1
                            &&
                            cruisesPP.length > props.gridNo
                            &&
                            <div className="swipercontainerstd gvgaddmarginbot">
                                <Swiper
                                slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
                                loop={false}
                                modules={[IonicSlides]}
                                // className="swiperaddspace"
                                // above was id before. add to parties swiper too?
                                // spaceBetween={6}
                                slidesOffsetBefore={10}
                                slidesOffsetAfter={10}
                                spaceBetween={2}
                                >
                                {
                                cruisesPP.map((cruise: any, index: number) => {
                                    return <SwiperSlide key={index}>
                                                <div className="swipercard">
                                                    <Link to={`/cruises/${cruise.id}`} style={{ display: 'contents' }}>
                                                        {
                                                            cruise.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={cruise.image_src} alt=""
                                                            onError={(e: any) => e.target.src = cruise.image_url}
                                                            />
                                                        }
                                                        {
                                                            !cruise.image_src
                                                            &&
                                                            <img className="gridviewcardimage" src={cruise.image_url} alt=""
                                                            />
                                                        }
                                                        <div className="gridviewcardimagecover">
                                                            

                                                            {/* <div className="gridviewcitybbl">
                                                            <span className="livefromcr">departs</span>{cruise.start_date}{" "}{cruise.start_month.slice(0,3)}
                                                        </div> */}
                                                        

                                                        <div className="newgridcaltt">
                                                            <div className="livefromnewoth">from</div>
                                                            
                                                            <div className="colorw">{cruise.start_date}{" "}{cruise.start_month.slice(0,3)}</div> 

                                                        </div>

                                                        <div className="gridviewbottomcontainer19">               
                                                            
                                                                                    {
                                                                                        cruise.name_short === ""
                                                                                        &&
                                                                                        <div className="gridviewtitlefull">
                                                                                        {cruise.name}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        cruise.name_short !== ""
                                                                                        &&
                                                                                        <div className="gridviewtitlefull">{cruise.name_short}</div>
                                                                                    }
                                                            
                                                        </div>
                                                                        
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                    })
                                }
                                </Swiper>
                            </div>
                            }

                       
                


                    {/* <div className="floatingbuttoncont">
                        

                       

                            {
                                selectedPerson.soundcloud !== ""
                                &&
                                <div className="socialmediabtn"
                                onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                selectedPerson.soundcloud === ""
                                &&
                                <div className="socialmediabtn"
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx" />
                                </div>
                            </div>
                            }

                        

                        {
                            selectedPerson.website !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPerson.website === ""
                            &&
                            <div className="socialmediabtn"
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninsta instabtn"
                        onClick={()=>window.open(`https://www.instagram.com/${selectedPerson?.instagram}`, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        
                        {
                            selectedPerson.spotify !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={()=>window.open(`https://www.tiktok.com/@${selectedPerson?.spotify}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPerson.spotify === ""
                            &&
                            <div className="socialmediabtn"
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                            
                            
                        
         
                        </div>

                        <div className="spaceforbtn"></div> */}
                    
                </div>
                                 
                </IonContent>
                ) : (
                    <div className="windowcontainerwithgrad">
                        <div className="ionbackbuttoncont">
                        <IonButtons
                        // slot="start"
                        >
                            <IonBackButton defaultHref="/" text="">
                            </IonBackButton>
                        </IonButtons>
                        </div>
                        <div className="innerwindowflex">
                            <div className="notfoundmsg">
                            This performer has been removed. Please click the back button.
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </IonPage>
    )
}

export default PersonWindow;