// geoGSON order thing needs to be checked. i think just go with alphabetical for the moment. and maybe do it in App
// map spot cards need improvement. also note the first header row cut to one line thing is slightly spilling into second header

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonMenuButton,
    IonModal,
    IonToggle,
    IonButton,
    IonSearchbar
} from '@ionic/react';

import {
    locationOutline,
    options,
    searchOutline,
    notificationsOutline,
    lockClosedOutline,
    heartSharp,
    heartOutline,
    reorderThreeOutline,
    chevronForwardOutline,
    calendarOutline,
    gridOutline,
    mapOutline,
    addOutline
} from 'ionicons/icons';

import MapViewMulti from './MapViewMulti';

import logohq from '../images/tgalogo_hq.png';


import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/searchbars.css';
import '../theme/spotcards.css';
import '../theme/filtermodals.css';
// import '../theme/swipers.css';
import '../theme/segments.css';


// Accent-folding function
const removeAccents = (str:string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };


const BarsAndClubs: React.FC<{
    isWeb: any;
    text: any;
    // gridNo: any;
    isWide: any;
    barsAndClubs: any[];
    // cityList: any,
    citySelected:any;
    // geoJSONBC: any;
    cityCoordinates: any;
    cityZoom: any;
    tagsBC: any;
    setTagsBC: any;
    // openPickerSelectCity: any;
    notifications: any;
    setNotifications: any;
    viewedNotifications: any;
    setViewedNotifications: any;
    viewedNotificationsAreLoaded: any;
    likedBarsAndClubs: any;
    setLikedBarsAndClubs: any;
    handleIncrement: any;
    handleDecrement: any;
}> = (props) => {
    
    

    const [BCView, setBCView] = useState("gridview");

    const [filteredBarsAndClubs, setFilteredBarsAndClubs] = useState<any>(null);
    const [filteredGeoJSONBC, setFilteredGeoJSONBC] = useState<any[]>([]);

    const [isLikedBCTagOn, setIsLikedBCTagOn] = useState(false);

    const [isFiltersOpenBC, setIsFiltersOpenBC] = useState(false);
    const [tagsSelectedBC, setTagsSelectedBC] = useState<any[]>([]);
    
    const [isNotificationsOpenBC, setIsNotificationsOpenBC] = useState(false);

    const [isSearchBarOpenBC, setIsSearchBarOpenBC] = useState(false);
    const [searchTextBC, setSearchTextBC] = useState("");

    const [searchTextBC2, setSearchTextBC2] = useState("");
    const [searchedForBC, setSearchedForBC] = useState<any>([]);

    // const [rootsContainingMarkers, setRootsContainingMarkers] = useState(null);

    // const [presentingElementBC, setPresentingElementBC] = useState<HTMLElement | undefined>(undefined);
    // why did i have null before instead of undefined

    // const pageBC = useRef<any>(null);
    // I added any above to make it work. why didnt I have to do this for Parties. what is the correct type?
    const modalTagsBC = useRef<HTMLIonModalElement>(null);
    const modalNotificationsBC= useRef<HTMLIonModalElement>(null);
    const modalSearchBC = useRef<HTMLIonModalElement>(null);

    
    const handleViewChangeBC = (viewClicked: string) => {
      if (viewClicked === BCView) return;
      if (viewClicked === "listview") setBCView("listview");
      if (viewClicked === "gridview") setBCView("gridview");
      if (viewClicked === "mapview") setBCView("mapview");
    };

    const handleOpenNotificationsBC = () => {
        setIsNotificationsOpenBC(true);
        const arrayOfNotificationsNotAlreadyInState: any = [];
        if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
            // const obj1 = {}
            arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
          }
          if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
            // const obj1 = {}
            arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
          }
        for (let i = 0; i < props.notifications.length; i++) {
          if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
            arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
          }
        }
        props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
      }
    
      const handleCloseNotificationsBC = () => {
        setIsNotificationsOpenBC(false);
      }

      const handleTogViewBC = () => {
        if (BCView === "gridview") setBCView("mapview")
        if (BCView === "mapview") setBCView("gridview")
      }

    const handleLikeBarOrClub = (event: any, barclubhash: any) => {
        event.preventDefault();
        // event.stopPropagation();
        // console.log(event.target.value, 'does this cause reset23');
        props.setLikedBarsAndClubs((prev: any)=>[...prev, barclubhash]);
        props.handleIncrement("venues", barclubhash);
    }
    
    const handleUnlikeBarOrClub = (event: any, barclubhash: any) => {
        event.preventDefault();
        // event.stopPropagation();
        props.setLikedBarsAndClubs(props.likedBarsAndClubs.filter((BClikedhash:any) => BClikedhash !== barclubhash));
        props.handleDecrement("venues", barclubhash);
    }
      
    const handleOpenSearchBarBC = () => {
        setIsSearchBarOpenBC(true);
    }
    
    const handleCloseSearchBarBC = () => {
        setIsSearchBarOpenBC(false);
        setSearchTextBC("");
        setSearchedForBC(props.barsAndClubs);
    }
    
    const handleSearchTextBC = ({ target }:any) => {
        setSearchTextBC(target.value);
    }

    const handleSearchTextBC2 = ({ target }:any) => {
        setSearchTextBC2(target.value);
    }

    const addTagsBC = (tag: string) => {
      if (tagsSelectedBC.includes(tag)) {
        const tagSelectedOneLess = tagsSelectedBC.filter((ele: any) => ele !== tag);
        setTagsSelectedBC(tagSelectedOneLess);
      } else {
        setTagsSelectedBC((prevTags: any) => {
          return prevTags.concat(tag);
        })
      }
    };
    
    const handleTurnOnTagBC = (event: CustomEvent) => {
      addTagsBC(event.detail.value);
    };

    // can combine the above two functions into one...

    // const handleOpenNotifications = () => {
    //   setIsNotificationsOpen(true);
    //   const arrayOfNotificationsNotAlreadyInState: any = [];
    //   for (let i = 0; i < props.notifications.length; i++) {
    //     if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.id === props.notifications[i].id).length === 0) {
    //       arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
    //     }
    //   }
    //   props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
    // }
  
    // const handleCloseNotifications = () => {
    //   setIsNotificationsOpen(false);
    // }


    const filterBCList = () => {

    let tempArrayOfFilteredBC:any = [];
    
    if (!isLikedBCTagOn) {
        tempArrayOfFilteredBC = props.barsAndClubs.filter(barclub => (
            (tagsSelectedBC.every(tag => barclub.tags_array.includes(tag)) || tagsSelectedBC.length === 0)
        ));
    }

    if (isLikedBCTagOn) {
        tempArrayOfFilteredBC = props.barsAndClubs.filter(barclub => (
            (tagsSelectedBC.every(tag => barclub.tags_array.includes(tag)) || tagsSelectedBC.length === 0)
            &&
            props.likedBarsAndClubs.filter((ele: any) => ele === barclub.hash).length > 0
        ));
    }
  
    
    setFilteredBarsAndClubs(tempArrayOfFilteredBC);


    const newGeoJSONObject:any = {
        "features": []
        }

        for (let i = 0; i < tempArrayOfFilteredBC.length; i++) {
        const newObj = {
            "type": "Feature",
            "properties": {
            "title": tempArrayOfFilteredBC[i].name,
            "description": i + 1
            },
            "geometry": {
            "coordinates": [tempArrayOfFilteredBC[i].lng, tempArrayOfFilteredBC[i].lat],
            "type": "Point"
            }
        }
        newGeoJSONObject.features.push(newObj);
        }
        
        setFilteredGeoJSONBC(newGeoJSONObject);


    };

    const filterBCListNew = () => {
        const tagsSelectedLength = tagsSelectedBC.length;
        const likedBarsAndClubsSet = new Set(props.likedBarsAndClubs);
      
        // Prepare search text
        const searchTextTrimmed = (searchTextBC2 || '').trim();
        const hasSearchText = searchTextTrimmed !== '';
        const transformedSearchText = hasSearchText
          ? removeAccents(searchTextTrimmed.toLowerCase())
          : '';
          //dont think it matters but note empty string default above vs null in Parties
      
        const filteredBarsAndClubs = [];
        const geoJSONFeatures = [];
      
        // Iterate over bars and clubs once
        for (let i = 0; i < props.barsAndClubs.length; i++) {
          const barclub = props.barsAndClubs[i];
      
          // Check if the bar or club includes all selected tags or if no tags are selected
          const hasAllTags =
            tagsSelectedLength === 0 ||
            tagsSelectedBC.every((tag) => barclub.tags_array.includes(tag));
      
          if (!hasAllTags) {
            continue; // Skip this bar or club
          }
      
          // If liked bars and clubs filter is on, check if the bar or club is liked
          if (isLikedBCTagOn && !likedBarsAndClubsSet.has(barclub.hash)) {
            continue; // Skip this bar or club
          }
      
          // Check if the bar or club's name matches the search text
          if (hasSearchText) {
            const barclubNameTransformed = removeAccents((barclub.name || '').toLowerCase());
            if (!barclubNameTransformed.includes(transformedSearchText)) {
              continue; // Skip this bar or club
            }
          }
      
          // If all conditions are met, include the bar or club
          filteredBarsAndClubs.push(barclub);
      
          // Build the GeoJSON feature
          const newObj = {
            type: 'Feature',
            properties: {
              title: barclub.name,
              description: filteredBarsAndClubs.length, // Use the current count as description
            },
            geometry: {
              type: 'Point',
              coordinates: [barclub.lng, barclub.lat],
            },
          };
          geoJSONFeatures.push(newObj);
        }
      
        // Set the filtered bars and clubs
        setFilteredBarsAndClubs(filteredBarsAndClubs);
      
        // Build the GeoJSON object
        const newGeoJSONObject:any = {
          features: geoJSONFeatures,
        };
        setFilteredGeoJSONBC(newGeoJSONObject);
      };

    // useEffect(() => {
    // setPresentingElementBC(pageBC.current);
    // }, []);

    useEffect(() => {
        if (!props.isWide && searchTextBC2 !== "") {
      
          setSearchTextBC2("");
        }
        else if (props.isWide && isSearchBarOpenBC && searchTextBC !== "") {
          console.log('why not heee');
          handleCloseSearchBarBC();
          
        } else if (props.isWide && isSearchBarOpenBC && searchTextBC === "") {
          setIsSearchBarOpenBC(false);
        }
      }, [props.isWide])


    useEffect(() => {
        filterBCListNew();
    }, [
    props.barsAndClubs,
    // props.citySelected,
    // isSearchBarOpenBC,
    // props.cityCoordinates,
    // searchTextBC,
    // props.geoJSONBC,
    // i am no longer getting geoJSON in App so getting rid of this props
    // above may be redundant since it will change with bars and clubs... but state async... but worth having two updates?
    // do i need current date and time if only to sync with parties and the whole 2 weeks upcoming thing
    props.tagsBC,
    tagsSelectedBC,
    isLikedBCTagOn,
    props.likedBarsAndClubs,
    searchTextBC2
    ]);
    // tma about last two. what about city selected? well geojson would change... well actually there is no city. this is global...

    useEffect(() => {
    

        // if (!searchTextBC) return;
    
            const tempArrayOfSearchedForBC = props.barsAndClubs.filter((barclub: any) => {
            const newName = removeAccents(barclub.name.toLowerCase());
                return newName.includes(searchTextBC.toLowerCase())
            }
            );
        setSearchedForBC(tempArrayOfSearchedForBC)
    }, [searchTextBC, props.barsAndClubs]);

    useEffect(() => {
    setTagsSelectedBC([]);
    }, [props.citySelected]);


    // useEffect(() => {
    //     console.log('BC reload');
    //   })

    return (
        <IonPage
        // ref={pageBC}
        >
          <IonHeader>
            <IonToolbar className="transparenttoolbar">
            <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">

                        <div className="abstlb">
                          <IonButtons className="btnposition">
                            <IonMenuButton color="primary" className="toolbaricontransparent" />
                          </IonButtons>
                        </div>
                          
                         
                            {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}

                        
                          
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotificationsBC}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
           
                    <div className="abscontionmenu">
                        <div className="toolbarother">
                                    <img className="trihq" src={logohq} alt="" />
                                    {/* <div className="logocirc">

                                    <img className="trihq" src={logohq} alt="" />
                                    </div> */}
                        
                        </div>
                    </div>
            
                    <div className="abscontionmenu">
                              {
                              props.barsAndClubs.length > 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBarBC}
                              />
                              }
                              {
                              props.barsAndClubs.length === 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              }
            </div>
            <div className="abscontionmenu">
                          {
                          props.barsAndClubs.length > 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon"   
                          onClick={() => setIsFiltersOpenBC(true)}           
                        //   onClick={handleOpenTags}
                          />
                          }
                          {
                          props.barsAndClubs.length === 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                          }

                          {
                          (tagsSelectedBC.length > 0 || isLikedBCTagOn)
                          &&
                          props.barsAndClubs.length > 0
                          
                          &&
                          <div className="filtercounter">{tagsSelectedBC.length + (isLikedBCTagOn ? 1 : 0)}</div>
                          }
              </div>
           
          </div>



          <div className="toolbar3">
                  <div className="searchtoolbarweb">
                    <IonSearchbar showClearButton="focus" animated={true} placeholder="Search for a venue . . ."
                    onIonInput={(event)=>handleSearchTextBC2(event)}
                    value={searchTextBC2}
                    className={searchTextBC2 === "" ? "searchbarpartiesweb" : "searchbarpartiesweb2"}
                    >
                    </IonSearchbar>
                    
                  </div>

                  <div className="menusectb">



                      

                      {
                              (tagsSelectedBC.length === 0)
                      
                              &&
                      <div className="menuicncontalt" onClick={() => setIsFiltersOpenBC(true)}>
                      <IonIcon icon={options} className="menicn2"></IonIcon>

                          
                              <div className="filtcirc">
                          
                              <IonIcon icon={addOutline} className="menicnfilt"></IonIcon>

                            </div>
                              
                    
                        
                            {/* Filter */}
                      </div>
                        }
                        {
                              (tagsSelectedBC.length > 0)
                      
                              &&
                      <div className="menuicncontalt2" onClick={() => setIsFiltersOpenBC(true)}>
                      <IonIcon icon={options} className="menicn22"></IonIcon>

                          
                            
                              
                      
                          
                              <div className="filtcirc">
                                <div className="menicnfiltnum">{tagsSelectedBC.length}</div>
                              

                            </div>
                            
                        
                        
                      </div>
                        }

                      <div className="newviewtog">
                        <div className={BCView === "gridview" ? "segtext segcolored" : "segtext"}
                        onClick={() => handleViewChangeBC("gridview")}
                        >
                          <IonIcon icon={gridOutline} className="grdic"></IonIcon>
                        </div>
                        <div className="segtog">
                          <IonToggle
                          className="grdcaltog"
                          value={BCView}
                          checked={BCView === "mapview"}
                          onIonChange={handleTogViewBC}
                          >
                          </IonToggle>
                        </div>
                        <div className={BCView === "mapview" ? "segtext segcolored" : "segtext"}
                        onClick={() => handleViewChangeBC("mapview")}
                        >
                          <IonIcon icon={mapOutline} className="calic"></IonIcon>
                        </div>
                        
                      </div>
                
              </div>
          </div>
               


                    <div className="mgdatesoth">

                    {/* <div className="gridviewsectionheader5"> */}
                    <div className="gridviewsectionheader6">
                            <div className="extbox pinkcol">
                                {/* {selectedFest.name} */}
                                {/* {props.citySelected} */}
                                {/* {props.citySelected.name_abbr} */}
                                {props.citySelected.name_abbr || props.citySelected.city}
                                </div>
                                {/* <div className="tinyball2"></div> */}
                                <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                                <div className="extbox whitecol">
                                {/* {selectedFest.datestring} */}
                                {props.text}
                                </div>

                                


                                
                        {/* <div className="sechead1">
                            <div className="secbbl">
                            {selectedFest.datestring}
                            </div>
                        </div>
                        
                        <div className="sechead2">
                            <div className="secbbl">
                            {selectedFest.name}
                            </div>
                        </div> */}
                        
                        
                        {/* <div className="sechead3"> 
                        <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                        </div> */}
                        
                    </div>



                    </div>
            </IonToolbar>
        </IonHeader>
          
    
        <IonContent fullscreen>

            {/* NOTIFICATIONS MODAL */}
          <IonModal
          ref={modalNotificationsBC}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpenBC}
          onDidDismiss={handleCloseNotificationsBC}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                    <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">
                                        <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                        <div className="notifcardmain">
                                            {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                            <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses that is committed to continuous improvement. Stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                            <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                        </div>      
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>

                                        <div className="notifcardmalt">
                                            {/* <div className="notifcardmsg">Go to the main menu and click settings:</div>
                                              <div className="setbtncont">
                                                <div className="selectsettbtnmalt">SETTINGS</div>
                                              </div>
                                            <div className="notifcardmsg">Set a default city for the app to load upon startup and select the city or cities for which you would like to receive notifications.</div> */}
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div>
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>
        
        
            {/* SEARCH MODAL */}
            <IonModal
            isOpen={isSearchBarOpenBC}
            ref={modalSearchBC}
            // presentingElement={presentingElementBC}
            // other components have exclamation mark in above
            // canDismiss={canDismiss}
            onDidDismiss={() => handleCloseSearchBarBC()}
            >
                <IonHeader>
                    <IonToolbar className="searchtoolbar">
                        <div className="searchtoolbardiv">
                            <IonSearchbar
                            showClearButton="focus"
                            animated={false}
                            placeholder="Search for a venue . . ."
                            onIonInput={(event)=>handleSearchTextBC(event)}
                            value={searchTextBC}
                            className="searchbarparties"
                            >
                            </IonSearchbar>
                            <IonButtons slot="end" className="closebutton">
                                <IonButton className="inncl" color="primary" onClick={handleCloseSearchBarBC}>Close</IonButton>
                            </IonButtons>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="searchmodalflexbox">
                                {
                                  searchedForBC.length === 0
                                  &&
                                  <div className="noparties2">No results found.</div>
                                }
                    <IonContent>
                    {searchedForBC.map((barclub: any, index: number) => {
                        return <Link key={index} to={`/barsandclubs/${barclub.id}`} style={{ display: 'contents' }}
                        // onClick={() => dismiss()}
                        onClick={() => handleCloseSearchBarBC()}
                        >
                            <div className="searchspotcard">
                                <div className="searchspotfirstsectionBC">
                                    {
                                        barclub.image_src
                                        &&
                                        <img className="searchfirstsectimgBC" src={barclub.image_src} alt=""
                                        onError={(e: any) => e.target.src = barclub.image_url}

                                        ></img>
                                    }
                                    {
                                        !barclub.image_src
                                        &&
                                        <img className="searchfirstsectimgBC" src={barclub.image_url} alt=""

                                        ></img>
                                    }
                                </div>
                                <div className="searchspotmainsection">
                                    
                                        <div className="searchspotmainsectinnert">{barclub.name}</div>
                                        <div className="searchspotmainsectinnerb">{barclub.suburb}</div>
                                  
                                </div>
                                <div className="searchspotlastsectionBC">
                                    <div className="searchspotBCballs">
                                        {
                                            barclub.monday_open !== ""
                                            &&
                                            <div className="spotballop">M</div>
                                        }
                                        {
                                            barclub.monday_open === ""
                                            &&
                                            <div className="spotballcl">M</div>
                                        }
                                        {
                                            barclub.tuesday_open !== ""
                                            &&
                                            <div className="spotballop">T</div>
                                        }
                                        {
                                            barclub.tuesday_open === ""
                                            &&
                                            <div className="spotballcl">T</div>
                                        }
                                        {
                                            barclub.wednesday_open !== ""
                                            &&
                                            <div className="spotballop">W</div>
                                        }
                                        {
                                            barclub.wednesday_open === ""
                                            &&
                                            <div className="spotballcl">W</div>
                                        }
                                        {
                                            barclub.thursday_open !== ""
                                            &&
                                            <div className="spotballop">Th</div>
                                        }
                                        {
                                            barclub.thursday_open === ""
                                            &&
                                            <div className="spotballcl">Th</div>
                                        }
                                            <div></div>
                                        {
                                            barclub.friday_open !== ""
                                            &&
                                            <div className="spotballop">F</div>
                                        }
                                        {
                                            barclub.friday_open === ""
                                            &&
                                            <div className="spotballcl">F</div>
                                        }
                                        {
                                            barclub.saturday_open !== ""
                                            &&
                                            <div className="spotballop">S</div>
                                        }
                                        {
                                            barclub.saturday_open === ""
                                            &&
                                            <div className="spotballcl">S</div>
                                        }
                                        {
                                            barclub.sunday_open !== ""
                                            &&
                                            <div className="spotballop">Su</div>
                                        }
                                        {
                                            barclub.sunday_open === ""
                                            &&
                                            <div className="spotballcl">Su</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link> 
                        })}
                    </IonContent>
                </div>
            </IonModal>

            {/* FILTERS MODAL */}
            <IonModal
            isOpen={isFiltersOpenBC}
            ref={modalTagsBC}
            onDidDismiss={()=>setIsFiltersOpenBC(false)}
            // initialBreakpoint={1}
            // breakpoints={[0, .25, .5, .75, 1]}
            className="filtersmodalstd"
            //changed above from id to class. see if still works.
            >
                <IonContent className="filtersmodalcontainer">
                {
                !props.isWeb
                &&
                    <div className="menusectheader2">
                        GENERAL
                    </div>
                    }
                    {
                !props.isWeb
                &&
                    <div className="filtersgrid">
                        <div className="filtersinnerflexbox"> 
                        <div className="filtername2">
                                Liked
                                {/* Liked */}
                                {/* {" ("}<IonIcon icon={heartSharp} className="filternameheart" color="danger"></IonIcon>{")"} */}
                                </div>
                                <IonToggle
                                slot="end"
                                value="Liked parties"
                                checked={isLikedBCTagOn}
                                onIonChange={()=>setIsLikedBCTagOn(!isLikedBCTagOn)}
                                >
                                </IonToggle>
                            {/* <div className="filtername">
                            Liked{" ("}<IonIcon icon={heartSharp} className="filternameheart" color="danger"></IonIcon>{")"}
                            </div>
                            <IonToggle
                            slot="end"
                            value="Liked parties"
                            checked={isLikedBCTagOn}
                            onIonChange={()=>setIsLikedBCTagOn(!isLikedBCTagOn)}
                            >
                            </IonToggle> */}
                        </div>
                    </div>
                }

                    <div className="menusectheader2">
                        OPEN
                    </div>
                    <div className="filtersgrid">
                        {
                        props.tagsBC.filter((ele: any) => ele.section === "open")
                        .map((tag: any, index: number) => {
                        return <div
                        key={index}
                        className="filtersinnerflexbox"
                        >
                            <div className="filtername2">{tag.name.slice(0,3)}</div>
                            <IonToggle
                            slot="end"
                            value={tag.name}
                            checked={tagsSelectedBC.includes(tag.name) ? true : false}
                            onIonChange={handleTurnOnTagBC}
                            >
                            </IonToggle>
                        </div>
                        })
                        }
                    </div>
                    <div className="menusectheader2">
                        INCLUDES
                    </div>
                    <div className="filtersgrid">
                        {
                        props.tagsBC.filter((ele: any) => ele.section === "includes")
                        .map((tag: any, index: number) => {
                        return <div
                        key={index}
                        className="filtersinnerflexbox"
                        >
                            <div className="filtername2">{tag.name}</div>
                            <IonToggle
                            slot="end"
                            value={tag.name}
                            checked={tagsSelectedBC.includes(tag.name) ? true : false}
                            onIonChange={handleTurnOnTagBC}
                            >
                            </IonToggle>
                        </div>
                        })
                        }
                    </div>
                    <div className="menusectheader2">
                        AMENITIES
                    </div>
                    <div className="filtersgrid">
                        {
                        props.tagsBC.filter((ele: any) => ele.section === "features")
                        .map((tag: any, index: number) => {
                        return <div
                        key={index}
                        className="filtersinnerflexbox"
                        >
                            <div className="filtername2">{tag.name}</div>
                            <IonToggle
                            slot="end"
                            value={tag.name}
                            checked={tagsSelectedBC.includes(tag.name) ? true : false}
                            onIonChange={handleTurnOnTagBC}
                            >
                            </IonToggle>
                        </div>
                        })
                        }
                    </div>
                    {/* different logic here */}
                    {
                        props.barsAndClubs.filter((barclub:any) => (barclub.tags_array.includes("Women") || tagsSelectedBC.includes("Women"))).length > 0
                        &&
                        <div className="menusectheader2">
                            AUDIENCE
                        </div>
                    }
                    {
                        props.barsAndClubs.filter((barclub:any) => (barclub.tags_array.includes("Women") || tagsSelectedBC.includes("Women"))).length > 0
                        &&
                    <div className="filtersgrid">
                        <div
                        
                        className="filtersinnerflexbox"
                        >
                            <div className="filtername2">Women</div>
                            <IonToggle
                            slot="end"
                            value="Women"
                            checked={tagsSelectedBC.includes("Women") ? true : false}
                            onIonChange={handleTurnOnTagBC}
                            >
                            </IonToggle>
                        </div>
                        
                    </div>
                    }
                </IonContent>
            </IonModal>

            {/* <div className="gridviewsectionheader4">
            
            {props.citySelected}
            <IonIcon icon={chevronForwardOutline}></IonIcon>
            {props.text}
            
          </div> */}

<div className="ultimategridviewcontainer">


                {BCView==="gridview"
            &&
                     !props.isWide
                     &&
                filteredBarsAndClubs?.length === 0 
                &&
                (tagsSelectedBC.length > 0 || isLikedBCTagOn)
                &&
                <div className="noparties gridviewlineheightsm">
                    There are no <span className="upcoming">bars or clubs</span> that correspond to the selected filter criteria. Please remove one or more filters.
                </div>
                }
                {BCView==="gridview"
            &&
                     props.isWide
                     &&
                filteredBarsAndClubs?.length === 0 
                &&
                (searchTextBC2 !== "" || tagsSelectedBC.length > 0 || isLikedBCTagOn)
                &&
                <div className="noparties gridviewlineheightsm">
                    There are no <span className="upcoming">bars or clubs</span> that correspond to the selected filter and/or search criteria.
                </div>
                }

                {BCView==="gridview"
            &&
                    filteredBarsAndClubs?.length === 0
                    &&
                    tagsSelectedBC.length === 0
                    &&
                    !isLikedBCTagOn
                    &&
                    searchTextBC2 === ""
                    &&
                    <div className="noparties gridviewlineheightsm">
                        No venues found.
                    </div>
                }
            
            {
            BCView==="gridview"
            &&
            
                
                <div className="gridviewgrid"
                >
                    {
                    filteredBarsAndClubs?.map((barclub: any, index: number) => {
                        return <Link key={index} to={`/barsandclubs/${barclub.id}`} style={{ display: 'contents' }}>
                            <div className="trybox2">
                            <div
                            className="gridviewcardstdnewnew"
                            >
                                {
                                barclub.image_src
                                &&
                                <img className="gridviewcardimage" src={barclub.image_src} alt=""
                                onError={(e: any) => e.target.src = barclub.image_url}
                                />
                                }
                                {
                                !barclub.image_src
                                &&
                                <img className="gridviewcardimage" src={barclub.image_url} alt=""
                                />
                                }
                                {
                !props.isWeb
                &&
                                <div className="gridviewcardimagecover2">

                                    {
                                    props.likedBarsAndClubs.filter((likedBChash: any) => likedBChash === barclub.hash).length === 0
                                    &&
                                    <div className="gridviewlikebuttoncontainer">
                                        <IonIcon
                                        icon={heartOutline}
                                        className="gridviewlikebutton"
                                        onClick={(event)=>handleLikeBarOrClub(event, barclub.hash)}
                                    />
                                    </div>
                                    }
                                    {
                                    props.likedBarsAndClubs.filter((likedBChash: any) => likedBChash === barclub.hash).length > 0
                                    &&
                                    <div className="gridviewlikebuttoncontainer">
                                        <IonIcon
                                        icon={heartSharp}
                                        color="danger"
                                        className="gridviewlikebutton"
                                        onClick={(event)=>handleUnlikeBarOrClub(event, barclub.hash)}
                                        />
                                    </div>
                                    }

                                 
                                    {/* <div className="gridviewBCballs">
                                        {
                                            barclub.monday_open !== ""
                                            &&
                                            <div className="gridballop">M</div>
                                        }
                                        {
                                            barclub.monday_open === ""
                                            &&
                                            <div className="gridballcl">M</div>
                                        }
                                        {
                                            barclub.tuesday_open !== ""
                                            &&
                                            <div className="gridballop">T</div>
                                        }
                                        {
                                            barclub.tuesday_open === ""
                                            &&
                                            <div className="gridballcl">T</div>
                                        }
                                        {
                                            barclub.wednesday_open !== ""
                                            &&
                                            <div className="gridballop">W</div>
                                        }
                                        {
                                            barclub.wednesday_open === ""
                                            &&
                                            <div className="gridballcl">W</div>
                                        }
                                        {
                                            barclub.thursday_open !== ""
                                            &&
                                            <div className="gridballop">Th</div>
                                        }
                                        {
                                            barclub.thursday_open === ""
                                            &&
                                            <div className="gridballcl">Th</div>
                                        }
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        {
                                            barclub.friday_open !== ""
                                            &&
                                            <div className="gridballop">F</div>
                                        }
                                        {
                                            barclub.friday_open === ""
                                            &&
                                            <div className="gridballcl">F</div>
                                        }
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        {
                                            barclub.saturday_open !== ""
                                            &&
                                            <div className="gridballop">S</div>
                                        }
                                        {
                                            barclub.saturday_open === ""
                                            &&
                                            <div className="gridballcl">S</div>
                                        }
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        {
                                            barclub.sunday_open !== ""
                                            &&
                                            <div className="gridballop">Su</div>
                                        }
                                        {
                                            barclub.sunday_open === ""
                                            &&
                                            <div className="gridballcl">Su</div>
                                        }
                                    </div> */}
                                   
                                    
                                </div>
                                }
                            </div>



                            <div className="gridviewbottomcontainertry">
                                    <div className="gridviewtitlecontainer">
                                        {
                                            barclub.name_short === ""
                                            &&
                                            <div className="gridviewtitlefulloth">{barclub.name}</div>
                                        }
                                        {
                                            barclub.name_short !== ""
                                            &&
                                            <div className="gridviewtitlefulloth">{barclub.name_short}</div>
                                        }
                                        
                                        

                                        <div className="midcont2">
                                                <div className="midcontdateballs">
                                                   {/* Closes at 2pm */}

                                                   {
                                            barclub.monday_open !== ""
                                            &&
                                            <div className="gridballop">M</div>
                                        }
                                        {
                                            barclub.monday_open === ""
                                            &&
                                            <div className="gridballcl">M</div>
                                        }
                                        {
                                            barclub.tuesday_open !== ""
                                            &&
                                            <div className="gridballop">T</div>
                                        }
                                        {
                                            barclub.tuesday_open === ""
                                            &&
                                            <div className="gridballcl">T</div>
                                        }
                                        {
                                            barclub.wednesday_open !== ""
                                            &&
                                            <div className="gridballop">W</div>
                                        }
                                        {
                                            barclub.wednesday_open === ""
                                            &&
                                            <div className="gridballcl">W</div>
                                        }
                                        {
                                            barclub.thursday_open !== ""
                                            &&
                                            <div className="gridballop">Th</div>
                                        }
                                        {
                                            barclub.thursday_open === ""
                                            &&
                                            <div className="gridballcl">Th</div>
                                        }
                                        {
                                            barclub.friday_open !== ""
                                            &&
                                            <div className="gridballop">F</div>
                                        }
                                        {
                                            barclub.friday_open === ""
                                            &&
                                            <div className="gridballcl">F</div>
                                        }
                                        {
                                            barclub.saturday_open !== ""
                                            &&
                                            <div className="gridballop">S</div>
                                        }
                                        {
                                            barclub.saturday_open === ""
                                            &&
                                            <div className="gridballcl">S</div>
                                        }
                                        {
                                            barclub.sunday_open !== ""
                                            &&
                                            <div className="gridballop">Su</div>
                                        }
                                        {
                                            barclub.sunday_open === ""
                                            &&
                                            <div className="gridballcl">Su</div>
                                        }
                                                </div>
                                                {/* <div className="tinyball">

                                                </div>
                                                <div className="midconttime">
                                                    {party.start_time}
                                                </div> */}
                                            </div>

                                            <div className="midcontalt">
                                                            {barclub.description}
                                            </div>
                                    </div>
                                       
                                </div>


                            </div>
                        </Link>
                        })
                        } 
                </div>
         
            }

            </div>

            {/* do i need another conditional to say that only show this section if geojsonexists. because liek what if bars and clubs loaded and someone in a fraction of time clicked map view. */}
            {
            BCView==="mapview"
            &&
            <MapViewMulti
            // BCView={BCView}
            filteredBarsAndClubs={filteredBarsAndClubs}
            filteredGeoJSONBC={filteredGeoJSONBC}
            cityCoordinates={props.cityCoordinates}
            cityZoom={props.cityZoom}
            // citySelected={props.citySelected}
            // rootsContainingMarkers={rootsContainingMarkers}
            // setRootsContainingMarkers={setRootsContainingMarkers}
            // fromCirc={false}
            />
            }

        </IonContent>

        <div className="segmentcon">
            <IonSegment
            id="check"
            value={BCView}
            className="segment-inner-container"
            swipeGesture={false}
            >

                <IonSegmentButton value="gridview"
                layout="icon-top"
                onClick={() => handleViewChangeBC("gridview")}
                >
                    {/* <IonLabel>GRID</IonLabel> */}
                    <IonLabel>Grid</IonLabel>
                    <IonIcon icon={gridOutline}></IonIcon>
                </IonSegmentButton>
                {
                    props.barsAndClubs.length > 0
                    &&
                    <IonSegmentButton value="mapview"
                    onClick={() => handleViewChangeBC("mapview")}
                >
                    {/* <IonLabel>CALENDAR</IonLabel> */}
                    <IonLabel>Map</IonLabel>
                    <IonIcon icon={mapOutline}></IonIcon>
                </IonSegmentButton>
                }





                
                {/* <IonSegmentButton
                value="gridview"
                onClick={() => handleViewChangeBC("gridview")}
                >
                    <IonLabel>GRID</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                value="mapview"
                onClick={() => handleViewChangeBC("mapview")}
                >
                    <IonLabel>MAP</IonLabel>
                </IonSegmentButton> */}
            </IonSegment>
        </div>
        
    </IonPage>

    )



}

export default BarsAndClubs;